import React, { useState, useEffect } from 'react';
import { Card, Table} from 'react-bootstrap';
import ViewInvoice from '../../../Utils/ViewInvoice';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import quoteListData from "./quote_data.json";
import { showAlert } from '../../../Utils/utils';
import { toast } from 'react-toastify';

export default function QuoteDraftLits() {
    const getStatusFromStatusID = (id) => {
        let status, color;
        switch (id) {
            case 0:
                status = "Pending";
                color = "danger";
                break;

            case 1:
                status = "";
                color = "";
                break;

            case 2:
                status = "";
                color = "";
                break;

            default:
                status = "";
                color = "";
                break;
        }

        return [status, color];
    };

    const HandlePIDelete = (POID) => {
        try {
            showAlert('Do you really want to delete this record?', 'confirm', async (result) => {
                if (result) {
                    // try {
                    //     const response = await deleteFromAPI(`FtsPurchaseOrderInvoice/${POID}/`);
                    //     if (response.status) {
                    //         toast.success('Purchase invoice deleted successfully', 'success');
                    //         FetchPIData();
                    //         UpdateDataTable();
                    //     }
                    // } catch (error) {
                    //     console.error('Error deleting purchase invoice:', error);
                    //     toast.error('purchase invoice Not Deleted', 'error');
                    // }
                }
            }, { confirmButtonText: 'Delete', cancelButtonText: 'Cancel', title: 'Are you sure?', confirmButtonColor: '#dc3545' });
        } catch (error) {
            console.error('Error deleting purchase invoice:', error);
            toast.error('Error deleting purchase invoice', 'error');
        }
    }

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#quoteDraftListDataTable')) {
            $('#quoteDraftListDataTable').DataTable({
                dom: "flrtip",
                data: quoteListData.quoteDraftListData.map(row => [
                    row.quote_number,
                    row.customer_name,
                    row.date,
                    row.total,
                    row.status,
                    row.id
                ]),
                columns: [
                    { title: "Quote#" },
                    { title: "Customer" },
                    { title: "Date" },
                    { title: "Total" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            const [status, color] = getStatusFromStatusID(0);
                            return (`<span class="badge bg-soft-${color}" >${status}</span>`)
                        }
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            // Add a class to the 'Action' column cells
                            td.classList.add('py-1');
                        },
                        render: function (data, type, row) {
                            return `
                        <div class='btn-list d-flex'>
                            <button class="btn btn-light btn-sm ql-dr-viewinvoice" data-ql-dr_id="${row[5]}">View Quote</button>
                            <button class="btn btn-light btn-sm" onClick="">Edit Quote</button>
                            <button class="btn btn-light btn-sm" onClick="">Print</button>
                            <button class="btn btn-soft-danger btn-sm px-3 ms-auto ql-dr-delete" data-ql-dr_id="${row[5]}">
                            <svg viewBox="0 0 24 24" role="presentation" class="icon-size-16"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path></svg>
                            </button>
                        </div>
                      `;
                        }
                    }
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
            document.querySelector('#quoteDraftListDataTable tbody').addEventListener('click', function (event) {
                // Check if the click happened inside a button with class 'ql-dr-delete'
                const deleteButton = event.target.closest('.ql-dr-delete');
                if (deleteButton) {
                    const pi_id = deleteButton.getAttribute('data-ql-dr_id');
                    HandlePIDelete(pi_id);
                }
                // Check if the click happened inside a button with class 'ql-dr-viewinvoice'
                const viewInvoiceButton = event.target.closest('.ql-dr-viewinvoice');
                if (viewInvoiceButton) {
                    const pi_id = viewInvoiceButton.getAttribute('data-ql-dr_id');
                    handleShowInvoice(pi_id);
                }
            });
        }
    }, []);

    // Invoice Data
    const fromInfoData = [
        { label: 'Quote#', value: 'Oct0001' },
        { label: 'Date', value: '24-10-2024' },
        { label: 'Vendor Name', value: 'Jems Bond' },
        { label: 'Address', value: '3019 Ersel Street Venus, TX 76084' },
        { label: 'Mobile Number', value: '+91 914-366-1459' },
        { label: 'Email', value: 'JemsBond007@armyspy.com' },
        { label: 'GSTIN', value: '29ABCDE1234F1Z5' }
    ];
    const toInfoData = [
        { label: 'Name', value: 'My Business' },
        { label: 'Address', value: '417 Edgewood Road Memphis, TN 38115' },
        { label: 'Mobile Number', value: '+91 914-366-1459' },
        { label: 'GSTIN', value: '123456789000' }
    ];
    const columns = [
        { header: '#', tableText: 'srno' },
        { header: 'Item', tableText: 'item' },
        { header: 'Description', tableText: 'description' },
        { header: 'Provider', tableText: 'provider' },
        { header: 'Unit Price', tableText: 'unitPrice' },
        { header: 'Quantity', tableText: 'quantity' },
        { header: 'Discount', tableText: 'discount' },
        { header: 'Discount Amount', tableText: 'discountAmount' },
        { header: 'Tax', tableText: 'tax' },
        { header: 'Tax Amount', tableText: 'taxAmount' },
        { header: 'Total', tableText: 'total' }
    ];
    const data = [
        { srno: '', item: '', description: '', provider: '', unitPrice: '', quantity: '', discount: '', discountAmount: '', tax: '', taxAmount: '', total: '' }
    ];
    const totalSummaryData = [
        { totalSummaryTitle: 'Sub Total', totalSummaryAmount: '5200' },
        { totalSummaryTitle: 'Discount', totalSummaryAmount: '0' },
        { totalSummaryTitle: 'Campaign', totalSummaryAmount: '0' },
        { totalSummaryTitle: 'Loyalty', totalSummaryAmount: '0' },
        { totalSummaryTitle: 'Taxable Amount', totalSummaryAmount: '4406.78' },
        { totalSummaryTitle: 'CGST', totalSummaryAmount: '396.61' },
        { totalSummaryTitle: 'SGST', totalSummaryAmount: '396.61' },
        { totalSummaryTitle: 'Total Tax Amount', totalSummaryAmount: '793.22' },
        { totalSummaryTitle: 'Grand Total', totalSummaryAmount: '5200' }
    ];
    const invoiceNotesData = [
        { invoiceNotesText: "Note Text" }
    ]
    // Add Invoice modal
    const [invoiceModalShow, setInvoiceModalShow] = useState(false);
    const handleShowInvoice = () => setInvoiceModalShow(true);
    const handleCloseInvoice = () => setInvoiceModalShow(false);

    return (
        <>
            <Card>
                <Card.Body>
                    <Table responsive bordered className='table-nowrap maindatatable' id='quoteDraftListDataTable'>
                        <thead className='table-light'></thead>
                    </Table>
                </Card.Body>
            </Card>

            {/* View Invoice Modal */}
            <ViewInvoice invoiceTitle="Quote Invoice" invoiceShow={invoiceModalShow} handleCloseInvoice={handleCloseInvoice} columns={columns} data={data} fromInfo={fromInfoData} toInfo={toInfoData} totalSummary={totalSummaryData} invoiceNotes={invoiceNotesData} />
        </>
    )
}
