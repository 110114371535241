import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';

// Create Context
export const AccountCategoryContext = createContext();

// Custom hook to use the AccountCategoryContext
export const useAccountCategory = () => useContext(AccountCategoryContext);

// Create a Context Provider component
export const AccountCategoryProvider = ({ children }) => {
    const [accountCategoryData, setAccountCategoryData] = useState([]);

    const fetchAccountCategoryData = async () => {
        try {
            const response = await getFromAPI('FtsAccountCategory/');
            setAccountCategoryData(response);
        } catch (error) {
            console.error('Error fetching Account Category data:', error);
        }
    };

    return (
        <AccountCategoryContext.Provider value={{ accountCategoryData, fetchAccountCategoryData }}>
            {children}
        </AccountCategoryContext.Provider>
    );
};
