import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Topbar from './Topbar';
import Icon from '@mdi/react';
import { mdiHomeVariantOutline, mdiCalendarCheckOutline, mdiAccountSearchOutline, mdiFileDocumentCheckOutline, mdiAccountGroupOutline, mdiStorefrontOutline, mdiPackageVariant, mdiTicketPercentOutline, mdiCalculatorVariantOutline, mdiAccountWrenchOutline, mdiBankOutline, mdiChevronDown, mdiDotsHorizontalCircleOutline, mdiBullhornOutline, mdiChartBar, mdiCogOutline, mdiInformationOutline, } from '@mdi/js';
import {getFromAPI} from '../../Utils/utils'
// Define menu items
const MENU_ITEMS = [
    { name: 'Dashboard', hasDropdown: false, to: '/dashboard', icon: mdiHomeVariantOutline },
    // { name: 'Appointment', hasDropdown: false, to: '/', icon: mdiCalendarCheckOutline },
    // {
    //     name: 'HRM',
    //     hasDropdown: true,
    //     icon: mdiAccountSearchOutline,
    //     dropdownItems: [
    //         { to: '/', label: 'Link One' },
    //         { to: '/', label: 'Link Two' },
    //     ],
    // },
    {
        name: 'Inventory',
        hasDropdown: true,
        icon: mdiFileDocumentCheckOutline,
        dropdownItems: [
            { to: '/manage_purchase', label: 'Manage Purchase' },
            { to: '/manage_transfer', label: 'Manage Transfer' },
        ],
    },
    // {
    //     name: 'Auth',
    //     hasDropdown: true,
    //     icon: mdiFileDocumentCheckOutline,
    //     dropdownItems: [
    //         { to: '/sign_up', label: 'Sign Up' },
    //         { to: '/', label: 'Login' },
    //     ],
    // },
    { name: 'Customers', hasDropdown: false, to: '/customers', icon: mdiAccountGroupOutline },
    { name: 'Vendors', hasDropdown: false, to: '/vendor', icon: mdiStorefrontOutline },
    { name: 'Product', hasDropdown: false, to: '/product', icon: mdiPackageVariant },
    {
        name: 'Sales',
        hasDropdown: true,
        icon: mdiTicketPercentOutline,
        dropdownItems: [
            { to: '/sales', label: 'Sales' },
            { to: '/sales_return', label: 'Sales Return' },
            { to: '/quote', label: 'Quote' },
            { to: '/challan', label: 'Challan' },
            { to: '/invoices', label: 'View Invoices' },
        ],
    },
    {
        name: 'Accounting',
        hasDropdown: true,
        icon: mdiCalculatorVariantOutline,
        dropdownItems: [
            { to: '/accounts', label: 'Accounts' },
            { to: '/ac_reports', label: 'Reports' },
            { to: '/book_closure', label: 'Book Closure' },
        ],
    },
    {
        name: 'Services',
        hasDropdown: true,
        icon: mdiAccountWrenchOutline,
        dropdownItems: [
            { to: '/services', label: 'Service' },
            { to: '/services_memberships', label: 'Services Memberships' },
        ],
    },
    { name: 'Bank Cash', hasDropdown: false, to: '/bank_cash', icon: mdiBankOutline },
    { name: 'Marketing', hasDropdown: false, to: '/marketing', icon: mdiBullhornOutline },
    { name: 'Reports', hasDropdown: false, to: '/reports_pages', icon: mdiChartBar },
    { name: 'Settings', hasDropdown: false, to: '/settings', icon: mdiCogOutline },
    { name: 'About Us', hasDropdown: false, to: '/about_us', icon: mdiInformationOutline },
];

export default function TopMenu() {
    const [isMoreMenuVisible, setIsMoreMenuVisible] = useState(false);
    const [visibleItems, setVisibleItems] = useState([]);
    const [moreItems, setMoreItems] = useState([]);
    const location = useLocation();
    const navbarRef = useRef(null);
    const moreMenuRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchActiveModules = async () => {
            try {
                const response = await getFromAPI(`FtsModules/?active=1`); // Adjust the URL as needed
                const activeModules = response.map(module => module.module_name);
                
                // Filter MENU_ITEMS based on activeModules from API
                const filteredItems = MENU_ITEMS.filter(item => activeModules.includes(item.name));

                setVisibleItems(filteredItems);
                setMoreItems([]);
            } catch (error) {
                console.error('Error fetching modules:', error);
            }
        };

        fetchActiveModules();
    }, []);


    const isActive = (path) => location.pathname === path;

    const isDropdownActive = (dropdownItems) => {
        return dropdownItems.some(item => isActive(item.to));
    };

    const isMoreDropdownActive = () => {
        return moreItems.some(item => item.hasDropdown ? isDropdownActive(item.dropdownItems) : isActive(item.to));
    };

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    useEffect(() => {
        const getItemWidth = () => {
            const width = navbarRef.current ? navbarRef.current.getBoundingClientRect().width : window.innerWidth;
            if (width >= 752 && width <= 1024) return 164;
            if (width >= 1025 && width <= 1199) return 160;
            if (width >= 1200 && width <= 1599) return 152;
            if (width >= 1600) return 154;
            return 160;
        };

        const handleResize = debounce(() => {
            if (navbarRef.current) {
                const navbarWidth = navbarRef.current.getBoundingClientRect().width;
                const itemWidth = getItemWidth();
                const maxVisibleItems = Math.floor(navbarWidth / itemWidth);

                if (navbarWidth < 752) {
                    setVisibleItems(MENU_ITEMS);
                    setMoreItems([]);
                } else if (maxVisibleItems < MENU_ITEMS.length) {
                    setVisibleItems(MENU_ITEMS.slice(0, maxVisibleItems));
                    setMoreItems(MENU_ITEMS.slice(maxVisibleItems));
                } else {
                    setVisibleItems(MENU_ITEMS);
                    setMoreItems([]);
                }
            }
        }, 200); // Debounce for 200ms

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
                setIsMoreMenuVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleMoreMenuToggle = () => {
        setIsMoreMenuVisible(!isMoreMenuVisible);
    };

    return (
        <>
            <div className="topbar-menu">
                <Topbar />
                <div className='topmenu'>
                    <Navbar bg="white" expand="md" className='topmenu-content'>
                        <Container id='topmenu-container' ref={navbarRef}>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    {visibleItems.map((item, index) => (
                                        item.hasDropdown ? (
                                            <NavDropdown
                                                key={index}
                                                title={
                                                    <>
                                                        <Icon path={item.icon} className='icon-size-22 top-navlink-icon' />
                                                        {' '}{item.name}
                                                        <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                                    </>
                                                }
                                                id={`nav-dropdown-${index}`}
                                                className={isActive(item.to) || isDropdownActive(item.dropdownItems) ? 'active' : ''}
                                                active={isActive(item.to) || isDropdownActive(item.dropdownItems)}
                                            >
                                                {item.dropdownItems.map((dropdownItem, i) => (
                                                    <NavDropdown.Item key={i} onClick={() => navigate(dropdownItem.to)}
                                                        className={isActive(dropdownItem.to) ? 'active' : ''}
                                                    >
                                                        {dropdownItem.label}
                                                    </NavDropdown.Item>
                                                ))}
                                            </NavDropdown>
                                        ) : (
                                            <Nav.Link key={index} as={Link} to={item.to}
                                                className={isActive(item.to) ? 'active' : ''}
                                            >
                                                <Icon path={item.icon} className='icon-size-22 top-navlink-icon' />
                                                {' '}{item.name}
                                            </Nav.Link>
                                        )
                                    ))}
                                    {moreItems.length > 0 && (
                                        <NavDropdown
                                            className={`more-drop-item ${isMoreDropdownActive() ? 'active' : ''}`}
                                            title={
                                                <>
                                                    <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                                    More
                                                    <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                                </>
                                            }
                                            align="end"
                                            id="nav-dropdown-more"
                                            show={isMoreMenuVisible}
                                            onClick={handleMoreMenuToggle}
                                            ref={moreMenuRef}
                                        >
                                            {moreItems.map((item, index) => (
                                                item.hasDropdown ? (
                                                    <NavDropdown
                                                        key={index}
                                                        title={
                                                            <>
                                                                {item.name}
                                                                <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                                            </>
                                                        }
                                                        id={`nav-dropdown-more-${index}`}
                                                        show
                                                        className={isDropdownActive(item.dropdownItems) ? 'active' : ''}
                                                    >
                                                        {item.dropdownItems.map((dropdownItem, i) => (
                                                            <NavDropdown.Item key={i} onClick={() => navigate(dropdownItem.to)}
                                                                className={isActive(dropdownItem.to) ? 'active' : ''}
                                                            >
                                                                {dropdownItem.label}
                                                            </NavDropdown.Item>
                                                        ))}
                                                    </NavDropdown>
                                                ) : (
                                                    <NavDropdown.Item key={index} onClick={() => navigate(item.to)}
                                                        className={isActive(item.to) ? 'active' : ''}
                                                    >
                                                        {item.name}
                                                    </NavDropdown.Item>
                                                )
                                            ))}
                                        </NavDropdown>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </>
    );
}
