import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default function ComboBox({ slectOptions, defualtValue, onChange, handleKeyDown }) {
    // select code
    const [inputValue, setInputValue] = useState(defualtValue.name);
    const [filteredWords, setFilteredWords] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        // Reset focused index and filter the words
        setFocusedIndex(-1);

        if (value && value.length > 0) {
            console.log(slectOptions);
            const filtered = slectOptions.filter((option) =>
                option.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredWords(filtered);
        } else {
            setFilteredWords([]);
        }
    };
    
    const handleItemClick = (product) => {
        setInputValue(product.name);
        setFilteredWords([]);
        onChange(product.id);
    };

    useEffect(() => {
        if(slectOptions && defualtValue) {
            const defualtOption = slectOptions.find((option) =>
                parseInt(option.id) === parseInt(defualtValue.id)
            );
            setInputValue(defualtOption.name);
        } else {setInputValue("");}
    }, [slectOptions, defualtValue]);

    return (
        <>
            <div className='input-select-form'>
            <Form.Control type="text" size='sm' placeholder="Select..." value={inputValue || ''} onChange={handleInputChange} onKeyDown={e => handleKeyDown(e)} />
                {filteredWords.length > 0 && (
                    <div className="input-select-list">
                        {filteredWords.map((option, index) => (
                            <div key={index} onClick={() => handleItemClick(option)} className={`input-select-item ${index === focusedIndex ? 'focused-item' : ''}`} > {option.name}</div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}
