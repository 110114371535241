import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import BranchWiseStockReportTableData from "./branch-wise-stock-report-table-data.json";

export default function BranchWiseStockReport() {
    // datatable
    const [branchWiseStockReportData] = useState(BranchWiseStockReportTableData);
  DataTable.Buttons.jszip(JSZip);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  useEffect(() => {
    if (!$.fn.dataTable.isDataTable('#mainDatatableBWSReport')) {
      $('#mainDatatableBWSReport').DataTable({
        dom: "flrtip",
        language: {
          search: '',
          searchPlaceholder: "Search...",
          paginate: {
            previous: '«',
            next: '»'
          },
        }
      });
    }
  }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-sm-0'>Branch Wise Stock Report</Card.Title>
                                            <Form className='min-w'>
                                                <Form.Control type="date" placeholder="Enter name" size='sm' name='salesInvoiceReturnDate' id='salesInvoiceReturnDate' />
                                            </Form>
                                        </div>

                                        <Form>
                                            <Row sm={2} lg={3} xl={4} xxl={5} className='row-cols-1 justify-content-center align-items-center'>
                                                <Col>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Time Duration</Form.Label>
                                                        <Form.Select  name='timeDuration' id='timeDuration'>
                                                            <option>All time</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Product Category</Form.Label>
                                                        <Form.Select  name='timeDuration' id='timeDuration'>
                                                            <option>Select product category</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Month Time Duration</Form.Label>
                                                        <Form.Select  name='monthTimeDuration' id='monthTimeDuration'>
                                                            <option>Select month time</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>GL Year Time Duration</Form.Label>
                                                        <Form.Select  name='glYearTimeDuration' id='glYearTimeDuration'>
                                                            <option>Select year time duration</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>GLF Year Time Duration</Form.Label>
                                                        <Form.Select  name='glfYearTimeDuration' id='glfYearTimeDuration'>
                                                            <option>Select year time duration</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <h5 className='mb-3 fs-16 text-end'>Total No of Products : 47566</h5>

                                        <Table responsive bordered id='mainDatatableBWSReport'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Product Name</th>
                                                    <th>Category Name</th>
                                                    <th>Vendor</th>
                                                    <th>BD Enterprise In-store Retail</th>
                                                    <th>BD Enterprise In-store Consumable</th>
                                                    <th>BD Enterprise On-floor Retail</th>
                                                    <th>BD Enterprise On-floor Consumable</th>
                                                    <th>NFS Adajan In-store Retail</th>
                                                    <th>NFS Adajan InStore Consuma</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {branchWiseStockReportData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.srNo}</td>
                                                        <td>{data.productName}</td>
                                                        <td>{data.categoyrName}</td>
                                                        <td>{data.vendor}</td>
                                                        <td>{data.bdEnterpriseInstoreRetail}</td>
                                                        <td>{data.bdEnterpriseInstoreConsumable}</td>
                                                        <td>{data.bdEnterpriseOnfloorRetail}</td>
                                                        <td>{data.bdEnterpriseOnfloorConsumable}</td>
                                                        <td>{data.nfsAdajanInstoreRetail}</td>
                                                        <td>{data.nfsAdajanInStoreConsuma}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
