import React from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';

export default function ProfitLoss() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-3 mb-sm-0'>Profit & Loss</Card.Title>
                                            <Form>
                                                <Form.Select  size="sm">
                                                    <option>All Time</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </Form>
                                        </div>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Expense</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Purchase Invoice</td>
                                                            <td>105476.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expense</td>
                                                            <td>69618.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sales Return</td>
                                                            <td>29142.31</td>
                                                        </tr>
                                                        <tr>
                                                            <td>On Account</td>
                                                            <td>20.00</td>
                                                        </tr>
                                                    </tbody>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Total Expense</th>
                                                            <th>204256.31</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Net Profit</td>
                                                            <td>575599.19</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>Total Amount</th>
                                                            <th>575599.19</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Income</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Invoice</td>
                                                            <td>764258.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Purchase Return</td>
                                                            <td>15597.50</td>
                                                        </tr>
                                                    </tbody>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Total Income</th>
                                                            <th>779855.5</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Net Loss</td>
                                                            <td>0</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>Total Amount</th>
                                                            <th>0</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
