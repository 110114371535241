import React from 'react';
import { Col, Row, Table, Button, Form, Modal } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiPrinter } from '@mdi/js';

export default function ViewInvoice({ invoiceShow, handleCloseInvoice, columns, data, fromInfo, toInfo, totalSummary, invoiceNotes, invoiceTitle }) {
    return (
        <>
            {/* View Invoice Modal */}
            <Modal centered show={invoiceShow} onHide={handleCloseInvoice} className='custom-size-modal'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>{invoiceTitle}</h5>
                    <Row>
                        <Col md={6} lg={5} xxl={4}>
                            {fromInfo && fromInfo.map((data, i) => (
                                <div className='d-flex gap-3 align-items-center py-3 border-bottom' key={i}>
                                    <p className='mb-0 w-120 text-secondary fs-16'>{data.label}</p>
                                    <p className='mb-0 text-secondary fs-16'>{data.value}</p>
                                </div>
                            ))}
                        </Col>
                        <Col md={6} lg={5} xxl={4} className='offset-lg-2 offset-xxl-4'>
                            {toInfo && toInfo.map((data, i) => (
                                <div className='d-flex gap-3 align-items-center py-3 border-bottom' key={i}>
                                    <p className='mb-0 w-120 text-secondary fs-16'>{data.label}</p>
                                    <p className='mb-0 text-secondary fs-16'>{data.value}</p>
                                </div>
                            ))}
                        </Col>
                    </Row>

                    <Table responsive bordered className='mt-5 mb-2'>
                        <thead className='table-light'>
                            <tr>
                                {columns && columns.map((col, index) => (
                                    <th key={index}>{col.header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((row, index) => (
                                <tr key={index}>
                                    {columns.map((col, colIndex) => (
                                        <td key={colIndex}>{row[col.tableText]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Row className={`justify-content-end mt-4${totalSummary ? '' : ' d-none'}`}>
                        <Col md={8} lg={6} xl={5}>
                            <Table responsive bordered striped className='mb-0'>
                                <tbody>
                                    {totalSummary && totalSummary.map((data, i) => (
                                        <tr key={i}>
                                            <th>{data.totalSummaryTitle}</th>
                                            <td>{data.totalSummaryAmount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col md={8} xl={9}>
                            <div className="d-flex flex-wrap gap-2">
                                {invoiceNotes && invoiceNotes.map((data, i) => (
                                    <div className="d-flex align-items-center p-2 border rounded w-100" key={i}>
                                        <p className="flex-grow-1 mb-0 text-muted px-3">{data.notes}</p>
                                        {/* <Button variant='soft-danger' className='ms-2'>Delete</Button> */}
                                    </div>
                                ))}
                            </div>
                        </Col>
                        {/* <Col md={4} xl={3}>
                            <div className="d-flex gap-3 align-items-center mt-3 mt-md-0">
                                <Button variant='primary' className='w-50'><Icon path={mdiPlus} className='btn-icon me-1' disabled />New Note</Button>
                                <Button variant='secondary' className='w-50'><Icon path={mdiPrinter} className='btn-icon me-1' disabled /> Print</Button>
                            </div>
                        </Col> */}
                    </Row>
                    {/* <Form className='mt-5'>
                        <Form.Group className="mb-3 custom-form-input" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Note Description</Form.Label>
                            <Form.Control as="textarea" rows={1} placeholder='Enter note description...' />
                        </Form.Group>
                        <div className="text-end">
                            <Button variant="primary me-2" onClick={handleCloseInvoice}>Save</Button>
                            <Button variant="secondary" onClick={handleCloseInvoice}>Cansel</Button>
                        </div>
                    </Form> */}
                </Modal.Body>
            </Modal>
        </>
    )
}
