import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import TempData from './temp_data.json';

export default function QuickCustomerModal({ addCustomerShow, addCustomerClose }) {
    const [TempDataOption] = useState(TempData);

    return (
        <>
            <Modal centered show={addCustomerShow} onHide={addCustomerClose} size='lg'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Add New Customer</h5>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Customer Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name..." name='customerName' id='customerName' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number..." name='mobileNumber' id='mobileNumber' />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email..." name='customerEmail' id='customerEmail' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.tempGender.map((data, i) =>
                                        <option key={i}>{data.tempGenderOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control type="date" placeholder="DD/MM/YYYY" name='customerBirthday' id='customerBirthday' />
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary me-2" onClick={addCustomerClose}>Add</Button>
                            <Button variant="secondary" onClick={addCustomerClose}>Close</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
