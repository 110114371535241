import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const ProductContext = createContext();

// Custom hook to use the CustomerContext
export const useProduct = () => useContext(ProductContext);

// Create a Context Provider component
export const ProductProvider = ({ children }) => {
    const [productData,setProductData] = useState([])

    const fetchProductCategoryData = async () => {
        try {
            const response = await getFromAPI('FtsProductCategory/');
            setProductData(response);
        } catch (error) {
            console.error('Error fetching Unit data:', error);
        }
    };

    return (
        <ProductContext.Provider value={{ productData, fetchProductCategoryData }}>
            {children}
        </ProductContext.Provider>
    );
};
