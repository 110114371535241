import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewBranchAdmin from './ViewBranchAdmin';
import NewBranchAdmin from './NewBranchAdmin';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

// import BranchAdminData from "./branch-admin-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

import { BranchAdminContext1 } from './branchAdminContext';
import { BranchAdminProvider, useBranchAdmin } from './getBranchAdminData';

function BranchAdmin() {

  const { getBranchAdmin, fetchBranchAdminData } = useBranchAdmin();
  const [selecetdBranchAdminId, setSelectedBranchAdminId] = useState('');

  useEffect(() => {
    fetchBranchAdminData();
  }, [selecetdBranchAdminId]);

  // left navlink active
  useEffect(() => {
    const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

    // Loop over all sidebar items
    sidebarItems.forEach((item) => {
      item.addEventListener('click', function () {
        // Remove active class from all sidebar links
        const activeLink = document.querySelector(".left-link-item a.active");
        if (activeLink) activeLink.classList.remove("active");

        // Add active class to the clicked item
        const link = this.querySelector('a');
        if (link) link.classList.add('active');

        // Update selected vendor ID
        const BranchAdminId = this.getAttribute('data-id');
        setSelectedBranchAdminId(BranchAdminId);

        // Trigger click on the "View Vendor" tab
        const viewBranchAdminTab = document.getElementById("pills-view-branch-admin-tab");
        if (viewBranchAdminTab) {
          viewBranchAdminTab.click();  // Ensure the tab is switched programmatically
        }

      });
    });

    // Cleanup listeners on component unmount
    return () => {
      sidebarItems.forEach((item) => {
        item.removeEventListener('click', () => { });
      });
    };
  }, [getBranchAdmin]);

  useEffect(() => {
    if (getBranchAdmin.length > 0&&!selecetdBranchAdminId) {
      setSelectedBranchAdminId(getBranchAdmin[0].staffid);
    }
  }, [getBranchAdmin,setSelectedBranchAdminId])

  const handleViewTab = () => {
    const viewTab = document.getElementById("pills-view-tab");
    if (viewTab) {
        viewTab.click();  // Ensure the tab is switched programmatically
    }
};

  return (
    <>
      <BranchAdminContext1.Provider value={selecetdBranchAdminId}>
        <div className="page-wrapper">
          <div className='page-content'>
            <Container>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <div className='d-md-flex justify-content-between align-items-center'>
                        <Form className='custom-form-width'>
                          <div className='custom-search-bar'>
                            <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                            <Form.Control className='' type="text" placeholder="Search branch..." name='branchSearch' id='branchSearch' />
                          </div>
                        </Form>
                        <div className="custom-top-nav-tab mt-3 mt-md-0">
                          <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="pills-view-branch-admin-tab" data-bs-toggle="pill" data-bs-target="#pills-view-branch-admin" type="button" role="tab" aria-controls="pills-view-branch-admin" aria-selected="true">
                                <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Branch Admin
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-new-branch-admin-tab" data-bs-toggle="pill" data-bs-target="#pills-new-branch-admin" type="button" role="tab" aria-controls="pills-new-branch-admin" aria-selected="false">
                                <Icon path={mdiPlus} className="btn-icon me-1" />New Branch Admin
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={4} xxl={3}>
                  <Card className='procust-left-list leftbar-list'>
                    <SimpleBarReact>
                      <Card.Body>
                        <div className='left-list-bar'>
                          {getBranchAdmin.map((data, i) =>
                            <Nav.Item className='left-link-item' key={i} data-id={data.staffid}>
                              <Nav.Link className={i === 0 ? "active" : ""} onClick={() => handleViewTab()}>
                                <h5 className='product-tab-title'>{data.firstname}</h5>
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </div>
                      </Card.Body>
                    </SimpleBarReact>
                  </Card>
                </Col>
                <Col lg={8} xxl={9}>
                  <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-view-branch-admin" role="tabpanel" aria-labelledby="pills-view-branch-admin-tab" tabIndex="0">
                      <ViewBranchAdmin />
                    </div>
                    <div className="tab-pane fade" id="pills-new-branch-admin" role="tabpanel" aria-labelledby="pills-new-branch-admin-tab" tabIndex="0">
                      <NewBranchAdmin />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </BranchAdminContext1.Provider>
    </>
  )
}

export default function CustomersWithProvider() {
  return (
    <BranchAdminProvider>
      <BranchAdmin />
    </BranchAdminProvider>
  );
}
