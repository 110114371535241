import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const BranchContext = createContext();

// Custom hook to use the CustomerContext
export const useBranch = () => useContext(BranchContext);

// Create a Context Provider component
export const BranchProvider = ({ children }) => {
    const [getBranchData, setBranchData] = useState([]);

    const fetchBranchData = async () => {
        try {
            const response = await getFromAPI('FtsBranch/');
            setBranchData(response);
        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };

    return (
        <BranchContext.Provider value={{ getBranchData, fetchBranchData }}>
            {children}
        </BranchContext.Provider>
    );
};
