import React, { useState } from 'react';
import { Card, Form, Row, Col, Table } from 'react-bootstrap';

// table data
import ExpenseTableData from "./trading-account-expense-data.json";
import IncomeTableData from "./trading-account-income-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

export default function TradingAccountData() {
    const [expenseData] = useState(ExpenseTableData);
    const [incomeData] = useState(IncomeTableData);
    return (
        <>
            <Card.Title>Trading Account</Card.Title>
            <Form className="d-sm-flex justify-content-between align-items-center mb-3">
                <div className="min-sm-w mb-3 mb-sm-0">
                    <Form.Select  size='sm'>
                        <option>All Time</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                    </Form.Select>
                </div>
                <div className="custom-form-width min-sm-w">
                    <div className='custom-search-bar'>
                        <Icon path={mdiMagnify} className="custom-search-input-icon icon-size-16" />
                        <Form.Control className='' type="text" placeholder="Search Closing Stock..." name='customersSearch' id='customersSearch' size='sm' />
                    </div>
                </div>
            </Form>

            <Row>
                <Col lg={6}>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Expense</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Opening Stock</th>
                                    <th>21,038,259.00</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Opening Stock</td>
                                    <td>21,038,259.00</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Purchase</th>
                                    <th>151,186.93</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expenseData.PurchaseData.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.purchase}</td>
                                        <td>{data.purchaseAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Trading Expense</th>
                                    <th>305,814.52</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expenseData.TradingExpenseData.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.tradingExpense}</td>
                                        <td>{data.tradingExpenseAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap mb-0'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Gross Profit</th>
                                        <th>248,989.27</th>
                                    </tr>
                                </thead>
                                <tfoot className='table-light'>
                                    <tr>
                                        <th>Total Expense</th>
                                        <th>21,744,249.72   </th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>

                </Col>

                <Col lg={6} className='mt-5 mt-lg-0'>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Income</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Total Sales</th>
                                    <th>705,990.72</th>
                                </tr>
                            </thead>
                            <tbody>
                                {incomeData.TotalSalesData.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.totalSales}</td>
                                        <td>{data.totalSalesAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap mt-auto mb-0'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Closing Stock</th>
                                        <th>21038259</th>
                                    </tr>
                                </thead>
                                <tfoot className='table-light'>
                                    <tr>
                                        <th>Total Income</th>
                                        <th>21,744,249.72</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
