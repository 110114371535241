import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewBrand from './ViewBrand';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

import BrandData from "./brand-data.json";

export default function SettingBrand() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);
    const [brandListData] = useState(BrandData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search brand..." name='brandSearch' id='brandSearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-brand-tab" data-bs-toggle="pill" data-bs-target="#pills-view-brand" type="button" role="tab" aria-controls="pills-view-brand" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Brand
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-brand-tab" data-bs-toggle="pill" data-bs-target="#pills-new-brand" type="button" role="tab" aria-controls="pills-new-brand" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Brand
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {brandListData.brandList.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.brandLink}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-brand" role="tabpanel" aria-labelledby="pills-view-brand-tab" tabIndex="0">
                                        <ViewBrand />
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-brand" role="tabpanel" aria-labelledby="pills-new-brand-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>New Brand</Card.Title>
                                                <Form>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Brand Name</Form.Label>
                                                        <Form.Control type="text" placeholder='Enter brand Name...' name='brandName' id='brandName' />
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className='text-end btn-list'>
                                                    <Button variant="primary">Add</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
