import React, { useState, useEffect } from 'react';
import { Card, Table, Form } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// cash table data
import YesBankTableData from "./yes-bank-table-data.json";

export default function YESBank() {
    // datatable
    const [yesBankData] = useState(YesBankTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableACYesBank')) {
            $('#mainDatatableACYesBank').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableACYesBank_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableACYesBank_wrapper").classList.remove("dtl-right");
        }
    }, []);
    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Yes Bank</Card.Title>
                <div>
                    <Form className='d-sm-flex align-items-center '>
                        <Form.Select  size="sm" className='me-sm-3 mb-sm-0 mb-3'>
                            <option>All Time</option>
                            <option value="1">All Time</option>
                            <option value="2">Two</option>
                        </Form.Select>
                        <Form.Select  size="sm">
                            <option>All</option>
                            <option value="1">Select One</option>
                            <option value="2">Two</option>
                        </Form.Select>
                    </Form>
                </div>
            </div>
            <Table responsive bordered className='table-nowrap' id='mainDatatableACYesBank'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr.No</th>
                        <th>Date</th>
                        <th>Payee</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Closing</th>
                        <th>Payment Mode</th>
                    </tr>
                </thead>
                <tbody>
                    {yesBankData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.srNo}</td>
                            <td>{data.date}</td>
                            <td>{data.payee}</td>
                            <td>{data.description}</td>
                            <td>{data.debit}</td>
                            <td>{data.credit}</td>
                            <td>{data.closing}</td>
                            <td>{data.paymentMode}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}
