import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const ExpenseCategoryContext = createContext();

// Custom hook to use the CustomerContext
export const useExpenseCategory = () => useContext(ExpenseCategoryContext);

// Create a Context Provider component
export const ExpenseCategoryProvider = ({ children }) => {
    const [expenseCategoryData,setExpenseCategoryData] = useState([])

    const fetchExpenseCategoryData = async () => {
        try {
            const response = await getFromAPI('FtsExpenseCategory/?isexpense=1');
            setExpenseCategoryData(response);
        } catch (error) {
            console.error('Error fetching Expense Category Data:', error);
        }
    };

    return (
        <ExpenseCategoryContext.Provider value={{ expenseCategoryData, fetchExpenseCategoryData }}>
            {children}
        </ExpenseCategoryContext.Provider>
    );
};
