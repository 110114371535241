import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import locationData from './location_data.json';
import TempData from './temp_data.json';
import { postToAPI, formatISODateWithTimezone } from './utils';
import { toast } from "react-toastify";

export default function QuickVendorModal({ addVendorShow, addVendorClose }) {
    const [TempDataOption] = useState(TempData);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const initialInputData = {
        name: '',
        company_name: null,
        phone_number1: '',
        email: '',
        gst_number: 'NA',
        country: 0,
        state: 0,
        city: 0,
        tax: 0,
        pan_card_number: null,
        opening_balance: 0.0,
        current_balance: 0.0,
        bank_branch_name: null,
        bank_branch_ifsc_code: null,
        bank_account_number: 0,
        website: null,
        phone_number2: null,
        primary_address: null,
        tax_id: 0,
        tax_type: 1,
        isactive: 1,
        isdeleted: 0,
        created_branch_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        created_user_id: 1,
        last_modified_user_id: 1
    };
    const [inputData, setInputData] = useState({ ...initialInputData });

    useEffect(() => {
        setCountries(locationData.countries);
    }, []);

    const handleCountryChange = (e) => {
        const selectedCountryName = e.target.value;
        setSelectedCountry(selectedCountryName);
        const country = locationData.countries.find(
            (country) => country.name === selectedCountryName
        );
        if (country) {
            setStates(country.states);
            setCities([]); // Reset cities when changing the country
            setSelectedState('');
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleStateChange = (e) => {
        const selectedStateName = e.target.value;
        setSelectedState(selectedStateName);
        const state = states.find((state) => state.name === selectedStateName);
        if (state) {
            setCities(state.cities);
        } else {
            setCities([]);
        }
    };

    const handleSubmit = async () => {
        if (!inputData.company_name || !inputData.company_name.trim()) {  // Fix: Add parentheses to trim()
            toast.error('Please Enter Company Name:', 'error');
            return;
        }
        if (!inputData.name || !inputData.name.trim()) {  // Fix: Add parentheses to trim()
            toast.error('Please Enter Contact Person Name:', 'error');
            return;
        }
    
        if (!inputData.email) {
            toast.error('Please Enter Email Address:', 'error');
            return;
        }
        if (!inputData.phone_number1) {
            toast.error('Please Enter Mobile Number:', 'error');
            return;
        }
    
        try {
            const response = await postToAPI("add_vendor/", inputData);
            if (response.status) {
                toast.success('Vendor Added Successfully', 'success');
                setInputData(initialInputData);
            } else {
                toast.error('Failed to add vendor');
            }
        } catch (error) {
            toast.error('Email or Mobile Number Already Exist');
        }
    };

    return (
        <>
            <Modal centered show={addVendorShow} onHide={addVendorClose} size='lg'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Add New Vendor</h5>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name..." name='vendorName' id='vendorName' value={inputData.company_name} onChange={(e) => setInputData({ ...inputData, company_name: e.target.value })}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number..." name='vendorNumber' id='vendorNumber' value={inputData.phone_number1} onChange={(e) => setInputData({ ...inputData, phone_number1: e.target.value })} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email..." name='vendorEmail' id='vendorEmail' value={inputData.email} onChange={(e) => setInputData({ ...inputData, email: e.target.value })} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" placeholder="Enter gst number..." name='vendorGstNumber' id='vendorGstNumber' value={inputData.gst_number} onChange={(e) => setInputData({ ...inputData, gst_number: e.target.value })} />
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select value={selectedCountry} onChange={handleCountryChange}>
                                        <option value="">Select Country</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select value={selectedState} onChange={handleStateChange} disabled={!states.length}>
                                        <option value="">Select State</option>
                                        {states.map((state, index) => (
                                            <option key={index} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select disabled={!cities.length}>
                                        <option value="">Select City</option>
                                        {cities.map((city, index) => (
                                            <option key={index} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTransactionTaxType.map((data, i) =>
                                        <option key={i}>{data.transactionTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" className='text-uppercase' placeholder="" name='vendorPan' id='vendorPan' />
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control type="number" placeholder="" name='vendorOpeningBalance' id='vendorOpeningBalance' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTaxType.map((data, i) =>
                                        <option key={i}>{data.taxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempGstTaxType.map((data, i) =>
                                        <option key={i}>{data.gstTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary me-2" onClick={addVendorClose}>Add</Button>
                            <Button variant="secondary" onClick={addVendorClose}>Close</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
