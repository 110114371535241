import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// auth pages
import SignUp from './components/auth_pages/SignUp';
import Login from './components/auth_pages/Login';

import TopMenu from './components/layout/TopMenu';
import Dashboard from './components/dashboard/Dashboard';
import Sales from './components/sales/Sales';
import Product from './components/product/Product';
import Customers from './components/customers/Customers';
import Vendor from './components/vendor/Vendor';
import ManagePurchase from './components/manage_purchase/ManagePurchase';
import ManageTransfer from './components/manage_transfer/ManageTransfer';
import SalesReturn from './components/sales/sales_return/SalesReturn';
import Quote from './components/sales/quote/Quote';
import Invoices from './components/sales/invoices/Invoices';
import Challan from './components/sales/challan/Challan';
import Services from './components/services/Services';
import ServicesMemberships from './components/services/services_memberships/ServicesMemberships';
import BankCash from './components/bank_cash/BankCash';
import Marketing from './components/marketing/Marketing';
import Accounts from './components/accounts/Accounts';
import Reports from './components/ac_reports/Reports';
import AccountSummary from './components/ac_reports/account_summary/AccountSummary';
import Transaction from './components/ac_reports/transaction/Transaction';
import AccountReceivable from './components/ac_reports/account_receivable/AccountReceivable';
import Income from './components/ac_reports/income/Income';
import Expense from './components/ac_reports/expense/Expense';
import ProfitLoss from './components/ac_reports/profit_loss/ProfitLoss';
import GeneralLedger from './components/ac_reports/general_ledger/GeneralLedger';
import PaymentBasedCollection from './components/ac_reports/payment_based_collection/PaymentBasedCollection';
import GstReports from './components/ac_reports/gst_reports/GstReports';
import CustomerLedger from './components/ac_reports/customer_ledger/CustomerLedger';
import VendorLedger from './components/ac_reports/vendor_ledger/VendorLedger';
import Calendar from './components/ac_reports/calendar/Calendar';
import ShippingTaxReport from './components/ac_reports/shipping_tax_report/ShippingTaxReport';
import AccountReceivableSummaryReport from './components/ac_reports/account_receivable_summary_report/AccountReceivableSummaryReport';
import TradingAccount from './components/ac_reports/trading_account/TradingAccount';
import OpeningBalanceReport from './components/ac_reports/opening_balance_report/OpeningBalanceReport';
import ClosingBalanceReport from './components/ac_reports/closing_balance_report/ClosingBalanceReport';
import GSTTaxReport from './components/ac_reports/gst_tax_report/GSTTaxReport';
import MonthWiseSalesInvoice from './components/ac_reports/month_wise_sales_invoice_tax/MonthWiseSalesInvoice';
import MonthWisePurchaseInvoice from './components/ac_reports/month_wise_purchase_invoice_tax/MonthWisePurchaseInvoice';
import PurchaseReturnReport from './components/ac_reports/purchase_return_report/PurchaseReturnReport';
import SalesReturnReport from './components/ac_reports/sales_return_report/SalesReturnReport';
import OpeningBalanceSheet from './components/ac_reports/opening_balance_sheet/OpeningBalanceSheet';
import BookClosure from './components/book_closure/BookClosure';
import ReportsPages from './components/reports/ReportsPages';
import SelfBranchStockReport from './components/reports/self_branch_stock_report/SelfBranchStockReport';
import BranchWiseStockReport from './components/reports/branch_wise_stock_report/BranchWiseStockReport';
import SelfBranchAlertQuantityReport from './components/reports/self-branch-alert-quantity-report/SelfBranchAlertQuantityReport';
import ProductLedgerReport from './components/reports/product_ledger_report/ProductLedgerReport';
import ProductWithoutHsnCode from './components/reports/product_without_hsn_code/ProductWithoutHsnCode';
import HsnWiseProductReport from './components/reports/hsn_wise_product_report/HsnWiseProductReport';
import GstDebuggingSalesReport from './components/reports/gst_debugging_sales_report/GstDebuggingSalesReport';
import GstDebuggingPurchaseReport from './components/reports/gst_debugging_purchase_report/GstDebuggingPurchaseReport';
import SalesInvoiceTaxSummary from './components/reports/sales_invoice_tax_summary/SalesInvoiceTaxSummary';
import SalesReturnTaxSummary from './components/reports/sales_return_tax_summary/SalesReturnTaxSummary';
import PurchaseInvoiceTaxSummary from './components/reports/purchase_invoice_tax_summary/PurchaseInvoiceTaxSummary';
import PurchaseReturnTaxSummary from './components/reports/purchase_return_tax_summary/PurchaseReturnTaxSummary';
import GstExpensesTaxSummary from './components/reports/gst_expenses_tax_summary/GstExpensesTaxSummary';
import YearlySummaryReport from './components/reports/yearly_summary_report/YearlySummaryReport';
import TDSCalculationReport from './components/reports/tds_calculation_report/TDSCalculationReport';
import GroupingLedgerReport from './components/reports/grouping_ledger_report/GroupingLedgerReport';
import Settings from './components/settings/Settings';
import AccountInfoSupport from './components/settings/account-info-support/AccountInfoSupport';
import ChangePassword from './components/settings/change_password/ChangePassword';
import CustomerFeedback from './components/settings/customer_feedback/CustomerFeedback';
import Branch from './components/settings/branch/Branch';
import BranchAdmin from './components/settings/branch_admin/BranchAdmin';
import Designation from './components/settings/designation/Designation';
import ProductCategory from './components/settings/product_category/ProductCategory';
import ServiceCategory from './components/settings/service_category/ServiceCategory';
import WeightUnit from './components/settings/weight_unit/WeightUnit';
import Unit from './components/settings/unit/Unit';
import SettingBrand from './components/settings/setting_brand/SettingBrand';
import PaymentMode from './components/settings/payment_mode/PaymentMode';
import ExpenseCategory from './components/settings/expense_category/ExpenseCategory';
import IncomeCategory from './components/settings/income_category/IncomeCategory';
import AccountCategory from './components/settings/account_category/AccountCategory';
import GSTConfiguration from './components/settings/gst_configuration/GSTConfiguration';
import AboutUs from './components/about_us/AboutUs';
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Auth pages */}
          <Route path='/' element={<Login />}></Route>
          <Route path='/sign_up' element={<SignUp />}></Route>

          <Route path="/dashboard" element={<><TopMenu /><Dashboard /></>} />
          <Route path='/vendor' element={<><TopMenu /><Vendor /></>}></Route>
          <Route path='/manage_purchase' element={<><TopMenu /><ManagePurchase /></>}></Route>
          <Route path='/manage_transfer' element={<><TopMenu /><ManageTransfer /></>}></Route>
          <Route path='/sales' element={<><TopMenu /><Sales /></>}></Route>
          <Route path='/sales_return' element={<><TopMenu /><SalesReturn /></>}></Route>
          <Route path='/quote' element={<><TopMenu /><Quote /></>}></Route>
          <Route path='/product' element={<><TopMenu /><Product /></>}></Route>
          <Route path='/customers' element={<><TopMenu /><Customers /></>}></Route>
          <Route path='/challan' element={<><TopMenu /><Challan /></>}></Route>
          <Route path='/invoices' element={<><TopMenu /><Invoices /></>}></Route>
          <Route path='/services' element={<><TopMenu /><Services /></>}></Route>
          <Route path='/services_memberships' element={<><TopMenu /><ServicesMemberships /></>}></Route>
          <Route path='/bank_cash' element={<><TopMenu /><BankCash /></>}></Route>
          <Route path='/marketing' element={<><TopMenu /><Marketing /></>}></Route>
          <Route path='/accounts' element={<><TopMenu /><Accounts /></>}></Route>
          <Route path='/ac_reports' element={<><TopMenu /><Reports /></>}></Route>
          <Route path='/book_closure' element={<><TopMenu /><BookClosure /></>}></Route>
          <Route path='/reports_pages' element={<><TopMenu /><ReportsPages /></>}></Route>
          <Route path='/settings' element={<><TopMenu /><Settings /></>}></Route>
          <Route path='/about_us' element={<><TopMenu /><AboutUs /></>}></Route>

          {/* Accounting reports pages */}
          <Route path='/account_summary' element={<><TopMenu /><AccountSummary /></>}></Route>
          <Route path='/transaction' element={<><TopMenu /><Transaction /></>}></Route>
          <Route path='/account_receivable' element={<><TopMenu /><AccountReceivable /></>}></Route>
          <Route path='/income' element={<><TopMenu /><Income /></>}></Route>
          <Route path='/expense' element={<><TopMenu /><Expense /></>}></Route>
          <Route path='/profit_loss' element={<><TopMenu /><ProfitLoss /></>}></Route>
          <Route path='/general_ledger' element={<><TopMenu /><GeneralLedger /></>}></Route>
          <Route path='/payment_based_collection' element={<><TopMenu /><PaymentBasedCollection /></>}></Route>
          <Route path='/gst_reports' element={<><TopMenu /><GstReports /></>}></Route>
          <Route path='/customer_ledger' element={<><TopMenu /><CustomerLedger /></>}></Route>
          <Route path='/vendor_ledger' element={<><TopMenu /><VendorLedger /></>}></Route>
          <Route path='/calendar' element={<><TopMenu /><Calendar /></>}></Route>
          <Route path='/shipping_tax_report' element={<><TopMenu /><ShippingTaxReport /></>}></Route>
          <Route path='/account_receivable_summary_report' element={<><TopMenu /><AccountReceivableSummaryReport /></>}></Route>
          <Route path='/trading_account' element={<><TopMenu /><TradingAccount /></>}></Route>
          <Route path='/opening_balance_report' element={<><TopMenu /><OpeningBalanceReport /></>}></Route>
          <Route path='/closing_balance_report' element={<><TopMenu /><ClosingBalanceReport /></>}></Route>
          <Route path='/gst_tax_report' element={<><TopMenu /><GSTTaxReport /></>}></Route>
          <Route path='/month_wise_sales_invoice' element={<><TopMenu /><MonthWiseSalesInvoice /></>}></Route>
          <Route path='/month_wise_purchase_invoice' element={<><TopMenu /><MonthWisePurchaseInvoice /></>}></Route>
          <Route path='/purchase_return_report' element={<><TopMenu /><PurchaseReturnReport /></>}></Route>
          <Route path='/sales_return_report' element={<><TopMenu /><SalesReturnReport /></>}></Route>
          <Route path='/opening_balance_sheet' element={<><TopMenu /><OpeningBalanceSheet /></>}></Route>

          {/* Reports pages */}
          <Route path='/self_branch_stock_report' element={<><TopMenu /><SelfBranchStockReport /></>}></Route>
          <Route path='/branch_wise_stock_report' element={<><TopMenu /><BranchWiseStockReport /></>}></Route>
          <Route path='/self-branch-alert-quantity-report' element={<><TopMenu /><SelfBranchAlertQuantityReport /></>}></Route>
          <Route path='/product_ledger_report' element={<><TopMenu /><ProductLedgerReport /></>}></Route>
          <Route path='/product_without_hsn_code' element={<><TopMenu /><ProductWithoutHsnCode /></>}></Route>
          <Route path='/hsn_wise_product_report' element={<><TopMenu /><HsnWiseProductReport /></>}></Route>
          <Route path='/gst_debugging_sales_report' element={<><TopMenu /><GstDebuggingSalesReport /></>}></Route>
          <Route path='/gst_debugging_purchase_report' element={<><TopMenu /><GstDebuggingPurchaseReport /></>}></Route>
          <Route path='/sales_invoice_tax_summary' element={<><TopMenu /><SalesInvoiceTaxSummary /></>}></Route>
          <Route path='/sales_return_tax_summary' element={<><TopMenu /><SalesReturnTaxSummary /></>}></Route>
          <Route path='/purchase_invoice_tax_summary' element={<><TopMenu /><PurchaseInvoiceTaxSummary /></>}></Route>
          <Route path='/purchase_return_tax_summary' element={<><TopMenu /><PurchaseReturnTaxSummary /></>}></Route>
          <Route path='/gst_expenses_tax_summary' element={<><TopMenu /><GstExpensesTaxSummary /></>}></Route>
          <Route path='/yearly_summary_report' element={<><TopMenu /><YearlySummaryReport /></>}></Route>
          <Route path='/tds_calculation_report' element={<><TopMenu /><TDSCalculationReport /></>}></Route>
          <Route path='/grouping_ledger_report' element={<><TopMenu /><GroupingLedgerReport /></>}></Route>

          {/* Setting pages */}
          <Route path='/account_info_support' element={<><TopMenu /><AccountInfoSupport /></>}></Route>
          <Route path='/change_password' element={<><TopMenu /><ChangePassword /></>}></Route>
          <Route path='/customer_feedback' element={<><TopMenu /><CustomerFeedback /></>}></Route>
          <Route path='/branch' element={<><TopMenu /><Branch /></>}></Route>
          <Route path='/branch_admin' element={<><TopMenu /><BranchAdmin /></>}></Route>
          <Route path='/designation' element={<><TopMenu /><Designation /></>}></Route>
          <Route path='/product_category' element={<><TopMenu /><ProductCategory /></>}></Route>
          <Route path='/service_category' element={<><TopMenu /><ServiceCategory /></>}></Route>
          <Route path='/weight_unit' element={<><TopMenu /><WeightUnit /></>}></Route>
          <Route path='/unit' element={<><TopMenu /><Unit /></>}></Route>
          <Route path='/setting_brand' element={<><TopMenu /><SettingBrand /></>}></Route>
          <Route path='/payment_mode' element={<><TopMenu /><PaymentMode /></>}></Route>
          <Route path='/expense_category' element={<><TopMenu /><ExpenseCategory /></>}></Route>
          <Route path='/income_category' element={<><TopMenu /><IncomeCategory /></>}></Route>
          <Route path='/account_category' element={<><TopMenu /><AccountCategory /></>}></Route>
          <Route path='/gst_configuration' element={<><TopMenu /><GSTConfiguration /></>}></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}

export default App;
