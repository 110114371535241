import React from 'react';
import { Modal } from 'react-bootstrap';
import FormInputs from './FormInputs';

export default function FormModal({ formModalShow, handleFormModalClose, title, rows, handleInputChange, handleSubmit, Buttons }) {
    return (
        <>
            <Modal centered show={formModalShow} size="xl" onHide={handleFormModalClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>{title}</h5>
                    <FormInputs Buttons={Buttons} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                </Modal.Body>
            </Modal>
        </>
    )
}
