import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { postToAPI, getFromAPI } from '../../Utils/utils';
import { useCustomer,CustomerProvider } from './getCustomerData'; // Import the useCustomer hook
import { toast} from "react-toastify";
import { fetchBankData,fetchCountryData,fetchTaxData,fetchCutsomerData } from '../../Utils/function_list';


export default function NewCustomer() {
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Tax, setTax] = useState([]);
    const [Customer,setCustomer] = useState([]);
    const [Bank,setBank] = useState([]);
    const { fetchCustomerData } = useCustomer();
    const [errors, setErrors] = useState({}); // State to store error messages

    const initialInputData = {
        name: '',
        email: '',
        phone_number2: '',
        gender: 'Male',
        birth_date: null,
        anniversary_date: null,
        address: null,
        reffered_by_id: 0,
        gst: 'NA',
        country: 0,
        state: 0,
        city: 0,
        tax: 0,
        pan: null,
        opening_balance: 0.0,
        bank_name: 0,
        ifsc_code: null,
        account_number: 'NA',
    };

    const [inputData, setInputData] = useState({...initialInputData});

    const validateField = (field, value) => {
        let error = '';
        
        switch (field) {
            case 'customer_name':
                if (!value) error = 'Please Enter Customer Name';
                break;
            case 'Mobile_Number':
                if (!value) error = 'Please Enter Mobile Number';
                break;
            case 'email':
                if (!value) error = 'Please Enter Email Address';
                break;
            default:
                break;
        }
        
        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: error,
        }));
        
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData(prevData => ({
            ...prevData,
            [name]: value
        }));
        
        // Validate field on change
        validateField(name, value);
    };
    

     // Handle input specifically for Mobile Number and Email
     const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    useEffect(() => {
        fetchCountryData(setCountry);
    }, []);

   useEffect (() =>{
       fetchBankData(setBank)
   },[]);


    useEffect(() => {
        fetchTaxData(setTax);
    }, []);


    useEffect(() => {
        fetchCutsomerData(setCustomer);
    }, []);

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response); // Save city data
        } catch (error) {
            console.error('Error fetching city data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
       
        if(!inputData.name)
        {
            toast.error('Please Enter Customer Name:','error')
            return;
        }
        if(!inputData.email)
        {
            toast.error('Please Enter Email Address:','error')
            return;
        }
        if(!inputData.phone_number2)
        {
            toast.error('Please Enter Mobile Number:','error')
            return;
        }       
        try {
            // Post the data to the API
            const response = await postToAPI("add_customer/", inputData);    
            if (response.status) {
                toast.success('Customer Added Successfully','success');
                fetchCustomerData(); // Update the customer list
                setInputData(initialInputData);
            } else {
                toast.error('Failed to add customer');
            }
        } catch (error) {
            console.error('Error adding customer:', error);
            console.log("Error Message:", error.message);
        }
    };
    
    

   // Function to extract state code and PAN from GST number
   const handleGSTChange = async (e) => {
    const gstNumber = e.target.value;
    setInputData(prevState => ({ ...prevState, GST: gstNumber }));

    if (gstNumber.length >= 12) {
        const stateCode = gstNumber.substring(0, 2); // First two digits as state code
        const panNumber = gstNumber.substring(2, 12); // Next ten digits as PAN

        try {
            // Fetch the state data using the extracted state code
            const response = await getFromAPI(`FtsState/?state_id=${stateCode}`);

            if (response.length > 0) {
                const stateName = response[0].state_name;
                setInputData(prevState => ({
                    ...prevState,
                    state: stateName,
                    pan: panNumber
                }));

                // Set the State select options based on the selected country if needed
                setState([{
                    state_id: stateCode,
                    state_name: stateName
                }]);
            } else {
                // Handle case where state is not found
                setInputData(prevState => ({
                    ...prevState,
                    state: '',
                    pan: ''
                }));
            }
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    } else {
        // Reset state and PAN if GST number is too short
        setInputData(prevState => ({
            ...prevState,
            state: '',
            pan: ''
        }));
    }
};


    return (
        <>
            <Card.Body className='pb-0'>
                <Card.Title>Add Customer</Card.Title>
                <Form>
                    <Row>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                   Customer Name <span className='text-danger'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    id='name'
                                    placeholder="Enter Customer Name..."
                                    value={inputData.name}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                  Email Address  <span className='text-danger'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name='email'
                                    id='email'
                                    placeholder="Enter Email Address..."
                                    value={inputData.email}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                  Mobile Number  <span className='text-danger'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name='phone_number2'
                                    id='phone_number2'
                                    placeholder="Please Enter Mobile Number..."
                                    value={inputData.phone_number2}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                    
                                    name='gender'
                                    id='gender'
                                    value={inputData.gender}
                                    onChange={e => setInputData({ ...inputData, gender: e.target.value })}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Birthday</Form.Label>
                                <Form.Control
                                    type="date"
                                    name='birth_date'
                                    id='birth_date'
                                    placeholder="DD/MM/YYYY"
                                    value={inputData.birth_date}
                                    onChange={e => setInputData({ ...inputData, birth_date: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Anniversary</Form.Label>
                                <Form.Control
                                    type="date"
                                    name='anniversary_date'
                                    id='anniversary_date'
                                    placeholder="DD/MM/YYYY"
                                    value={inputData.anniversary}
                                    onChange={e => setInputData({ ...inputData, anniversary_date: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='address'
                                    id='address'
                                    placeholder="Enter address..."
                                    value={inputData.address}
                                    onChange={e => setInputData({ ...inputData, address: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Referred By</Form.Label>
                                <Form.Select
                                    
                                    name='referred_by'
                                    id='referred_by'
                                    value={inputData.reffered_by_id}
                                    onChange={e => setInputData({ ...inputData, reffered_by_id: e.target.value })}
                                >
                                    <option value="">None</option>
                                    {Customer.map(item => (
                                        <option key={item.id} value = {item.userid}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>GST</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='GST'
                                    id='GST'
                                    placeholder="Enter GST number..."
                                    value={inputData.gst}
                                    onChange={e => setInputData({ ...inputData, gst: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                    
                                    name='country'
                                    id='country'
                                    value={inputData.country}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select Country</option>
                                    {country.map(item => (
                                        <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>State</Form.Label>
                                <Form.Select
                                    
                                    name='state'
                                    id='state'
                                    value={inputData.state}
                                    onChange={handleStateChange}
                                >
                                    <option value="">Select State</option>
                                    {State.map(item => (
                                        <option key={item.id} value={item.state_code}>{item.state_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>City</Form.Label>
                                <Form.Select
                                    
                                    name='city'
                                    id='city'
                                    value={inputData.city}
                                    onChange={e => setInputData({ ...inputData, city: e.target.value })}
                                >
                                    <option value="">Select City</option>
                                    {city.map(item => (
                                        <option key={item.id} value={item.id}>{item.city_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Tax</Form.Label>
                                <Form.Select
                                    
                                    name='tax'
                                    id='tax'
                                    value={inputData.tax}
                                    onChange={e => setInputData({ ...inputData, tax: e.target.value })}
                                >
                                    <option value="">Select Tax</option>
                                    {Tax.map(item => (
                                        <option key={item.id} value = {item.id}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>PAN</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='pan'
                                    id='pan'
                                    placeholder="Enter PAN number..."
                                    value={inputData.pan}
                                    onChange={e => setInputData({ ...inputData, pan: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Opening Balance</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='opening_balance'
                                    id='opening_balance'
                                    placeholder="Enter opening balance..."
                                    value={inputData.opening_balance}
                                    onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                          <div className="mb-3 custom-form-input">
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Select
                                    
                                    name='bank_name'
                                    id='bank_name'
                                    value={inputData.bank_name}
                                    onChange={e => setInputData({ ...inputData, bank_name: e.target.value })}
                                >
                                    <option value="">Select Bank Name</option>
                                    {Bank.map(item => (
                                        <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>IFSC Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='ifsc_code'
                                    id='ifsc_code'
                                    placeholder="Enter IFSC code..."
                                    value={inputData.ifsc_code}
                                    onChange={e => setInputData({ ...inputData, ifsc_code: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='account_number'
                                    id='account_number'
                                    placeholder="Enter Account Number..."
                                    value={inputData.account_number}
                                    onChange={e => setInputData({ ...inputData, account_number: e.target.value })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                </Form>
            </Card.Body>
        </>
    );
}
