import React from 'react';
import { Card, Button, Form, Row, Col, Table } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function Membership() {
    return (
        <>

            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>Membership</Card.Title>
                        <div className='d-flex gap-3'>
                            <Button variant="soft-info p-0" >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0">
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="outline-primary">Enable DND</Button>{' '}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={6} xl={5} xxl={3}>
                    <Form>
                        <div className="mb-3 custom-form-input">
                            <Form.Label>Select Membership</Form.Label>
                            <Form.Select >
                                <option>All</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </Form>
                </Col>
            </Row>

            <Table responsive bordered className='table-lg table-nowrap'>
                <thead className='table-light'>
                    <tr>
                        <th>Membership</th>
                        <th>Activate date</th>
                        <th>Expiry date</th>
                        <th>Validity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Premium Membership</td>
                        <td>28/12/2022</td>
                        <td>30/12/2022</td>
                        <td>2 Years</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
