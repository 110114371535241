import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { formatISODateWithTimezone, getFromAPI, postToAPI } from '../../../Utils/utils'
import AccountCategoryData from "./account-category-data.json";
import { toast } from 'react-toastify';

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';
import ViewAccountCategory from './ViewAccountCategory';
import { accountCategoryContext1 } from './accountCategoryContext';
import { AccountCategoryProvider, useAccountCategory } from './getAccountCategory';

function AccountCategory() {

    const [selectedAccountCategory, setSelectedAccountCategory] = useState('');
    const [category, setCategory] = useState([]);
    const { accountCategoryData, fetchAccountCategoryData } = useAccountCategory();
    const [account, setAccount] = useState([]);
    const [selectedAccountType, setSelectedAccountType] = useState(null);

    const initialInputData = {
        name: '',
        code: 'NA',
        index_no: 0.0,
        bs_anx: 0,
        account_master_id: 0,
        type: 0,
        created_branch_id: 0,
        created_user_id: 0,
        last_modfied_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date),
        isdeleted: 0,
        reserve_varchar1: 'NA',
        reserve_varchar2: 'NA',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0,
        reserve_date1: 0.0
    }

    const [inputData, setInputData] = useState(initialInputData)

    const handleCancel = () => {
        setInputData(initialInputData);
    }
    useEffect(() => {
        fetchAccountCategoryData(setCategory);
    }, [selectedAccountCategory]);

    const handleAddAccountCategoryData = async () => {
        if (!inputData.name) {
            toast.error('Please Enter Account Category Name');
            return;
        }
        if (inputData.account_master_id.length === 0) {
            toast.error('Please Select Account Type');
            return;
        }
        try {
            const response = await postToAPI('FtsAccountCategory/', inputData);

            if (response.status) {
                toast.success('Account Category Added Successfully');
                setInputData(initialInputData);
            }
            else {
                toast.error('Failed to add Account Category');
            }

        } catch (error) {
            toast.error('Failed to add Account Category');
        }
    };

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const categoryId = this.getAttribute('data-id');
                setSelectedAccountCategory(categoryId);

                // Trigger click on the "View Unit" tab
                const viewCategoryTab = document.getElementById("pills-view-account-category-tab");
                if (viewCategoryTab) {
                    viewCategoryTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [accountCategoryData]);

    useEffect(() => {
        if (accountCategoryData.length > 0&& !selectedAccountCategory) {
            // No vendors available, reset selectedCustomerId
            setSelectedAccountCategory(accountCategoryData[0].id);
        }
    }, [accountCategoryData, setSelectedAccountCategory]);  // Trigger on changes to 'vendor' and 'selectedCustomerId'


    // Function to fetch account data
    const fetchAccountData = async () => {
        try {
            const response = await getFromAPI('FtsAccountMaster/');
            if (response) {
                setAccount(response);
            } else {
                toast.error('Failed to fetch Account data');
            }
        } catch (e) {
            toast.error('Failed to fetch Account data');
        }
    };

    // useEffect to fetch data on component mount
    useEffect(() => {
        fetchAccountData();
    }, []); // Empty dependency array ensures this runs only once on mount

    // Function to handle account type selection
    const handleAccountTypeChange = (e) => {
        const selectedAccountId = e.target.value;
        setInputData({ ...inputData, account_master_id: selectedAccountId });

        // Find the selected account in the list and update the account type state
        const selectedAccount = account.find(acc => acc.id === parseInt(selectedAccountId, 10));
        if (selectedAccount) {
            setSelectedAccountType(selectedAccount.type);
        }
    };

    // Function to handle radio button selection
    const handleRadioChange = (value) => {
        setInputData({ ...inputData, type: value });
    };

    const [accountCategoryListData] = useState(AccountCategoryData);
    return (
        <>
            <accountCategoryContext1.Provider value={selectedAccountCategory}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-md-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className='' type="text" placeholder="Search Accounts..." name='accountsSearch' id='accountsSearch' />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-view-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-view-account-category" type="button" role="tab" aria-controls="pills-view-account-category" aria-selected="true">
                                                                <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Account Category
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-new-account-category" type="button" role="tab" aria-controls="pills-new-account-category" aria-selected="false">
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Account Category
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between align-items-center'>
                                                    <Form className='custom-form-width'>
                                                        <div className='custom-search-bar'>
                                                            <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                            <Form.Control className='' type="text" placeholder="Search Accounts..." name='accountsSearch' id='accountsSearch' />
                                                        </div>
                                                    </Form>
                                                    <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                        <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" id="pills-view-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-view-account-category" type="button" role="tab" aria-controls="pills-view-account-category" aria-selected="true">
                                                                    <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Account Category
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-new-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-new-account-category" type="button" role="tab" aria-controls="pills-new-account-category" aria-selected="false">
                                                                    <Icon path={mdiPlus} className="btn-icon me-1" />New Account Category
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {accountCategoryData.map((data, i) =>
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                            <Nav.Link className={i === 0 ? "active" : ""}>
                                                                <h5 className='product-tab-title'>{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>

                                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-view-account-category" role="tabpanel" aria-labelledby="pills-view-account-category-tab" tabIndex="0">
                                            <ViewAccountCategory />
                                        </div>
                                        <div className="tab-pane fade" id="pills-new-account-category" role="tabpanel" aria-labelledby="pills-new-account-category-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <Card.Title>New Account Category</Card.Title>
                                                    <Form>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Account Category Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder='Enter account category name...'
                                                                        name='name'
                                                                        id='name'
                                                                        value={inputData.name}
                                                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xl={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Code</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder='Enter Code...'
                                                                        name='accountCategoryCode'
                                                                        id='accountCategoryCode'
                                                                        value={inputData.code}
                                                                        onChange={e => setInputData({ ...inputData, code: e.target.value })}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xl={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Account type</Form.Label>
                                                                    <Form.Select
                                                                        name='account_master_id'
                                                                        id='account_master_id'
                                                                        value={inputData.account_master_id}
                                                                        onChange={handleAccountTypeChange}
                                                                    >

                                                                        <option value="">Select Account Type</option>
                                                                        {account.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <ul className='list-inline'>
                                                                    {selectedAccountType === 2 ? (
                                                                        <>
                                                                            <li className='list-inline-item'>
                                                                                {['radio'].map((type) => (
                                                                                    <div key={`expense-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            type={type}
                                                                                            name="account-radio"
                                                                                            id={`expense-check-${type}`}
                                                                                            label="Expense"
                                                                                            defaultChecked={true}
                                                                                            checked={inputData.type === '1'}
                                                                                            onChange={() => handleRadioChange('1')} // Save 1 for Expense
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </li>
                                                                            <li className='list-inline-item'>
                                                                                {['radio'].map((type) => (
                                                                                    <div key={`income-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            type={type}
                                                                                            name="account-radio"
                                                                                            id={`income-check-${type}`}
                                                                                            label="Income"
                                                                                            checked={inputData.type === '2'}
                                                                                            onChange={() => handleRadioChange('2')} // Save 2 for Income

                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </li>
                                                                        </>
                                                                    ) : selectedAccountType === 1 ? (
                                                                        <>
                                                                            <li className='list-inline-item'>
                                                                                {['radio'].map((type) => (
                                                                                    <div key={`liability-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            type={type}
                                                                                            name="account-radio"
                                                                                            id={`liability-check-${type}`}
                                                                                            label="Liability"
                                                                                            defaultChecked={true}
                                                                                            checked={inputData.type === '1'}
                                                                                            onChange={() => handleRadioChange('1')} // Save 1 for Liability
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </li>
                                                                            <li className='list-inline-item'>
                                                                                {['radio'].map((type) => (
                                                                                    <div key={`assets-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            type={type}
                                                                                            name="account-radio"
                                                                                            id={`assets-check-${type}`}
                                                                                            label="Assets"
                                                                                            checked={inputData.type === '2'}
                                                                                            onChange={() => handleRadioChange('2')} // Save 2 for Assets
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </li>
                                                                        </>
                                                                    ) : null}
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                                <div className='custom-card-footer'>
                                                    <div className='text-end btn-list'>
                                                        <Button variant="primary" onClick={handleAddAccountCategoryData}>Add</Button>{' '}
                                                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </accountCategoryContext1.Provider>

        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <AccountCategoryProvider>
            <AccountCategory />
        </AccountCategoryProvider>
    );
}