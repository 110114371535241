import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import {getFromAPI,postToAPI,formatISODateWithTimezone} from "../../../Utils/utils";
import {toast} from 'react-toastify'; 
import { useBranch,BranchProvider } from './getBranchData'; // Import the useCustomer hook


export default function NewBranch() {

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    const initialInputData = {
        name : '',
        business_name : '',
        location: '',
        country : 0,
        state : 0,
        city : 0,
        phone_number_1 : '',
        pan_card_number : 'NA',
        gst_number : 'NA',
        tax_id : 0,
        tax_type : 1,
        branch_type_id : 0,
        bank_name : 0,
        bank_account_no : '0',
        bank_ifsc_code : '0',
        owner_user_id : 1,
        created_user_id : 1,
        last_modified_user_id : 1,
        created_date_time : formatISODateWithTimezone(new Date()),
        last_modified_date_time : formatISODateWithTimezone(new Date()),
        isonline : 0,
        isactive : 1,
        isdeleted : 0,
        reserve_varchar1 : 'NA',
        reserve_varchar2 : 'NA',
        reserve_float1 : 0.0,
        reserve_float2 : 0.0,
        reserve_int1 : 0,
        reserve_int2: 0,
        reserve_double1 : 0.0,
        reserve_date1: new Date().toISOString().split('T')[0]
       };
      const { fetchBranchData } = useBranch();
      const[branchInputData,setBranchInputData] = useState({...initialInputData});
      const [countryData,setCountryData] = useState([]);
      const [stateData,setStateData] = useState([]);
      const [cityData,setCityData] = useState([]);
      const [bankData,setbankData] = useState([]);
      const [taxData,setTaxData] = useState([]);
      // Function for handle input for phone_number
      const handleMobileChange = (e) => {
       const {name,value} = e.target;
       //Remove space from the input value
       setBranchInputData((prev) => ({
           ...prev,
           [name] : value.replace(/\+/g,''),
       }));
      };

   // Function for fetchCountryData
   useEffect(() =>{
      const fetchCountryData = async () => {
       try {
           const response = await getFromAPI("FtsCountries/");
           setCountryData(response);
       }
        catch(error) 
        {
           console.error('Error fetching country data', error);
        }
       };
       fetchCountryData();
      },[]);

      useEffect(() => {
       const fetchBankData = async () => {
           try {
               const response = await getFromAPI('FtsBanks/');
               setbankData(response); // Save tax data
           } catch (error) {
               console.error('Error fetching tax data:', error);
           }
       };

       fetchBankData();
     }, []);

     useEffect(() => {
       const fetchTaxData = async () => {
           try {
               const response = await getFromAPI('FtsTaxes/');
               setTaxData(response); // Save tax data
           } catch (error) {
               console.error('Error fetching tax data:', error);
           }
       };

       fetchTaxData();
    }, []);
   
    const handleCountryChange = async (e) => {
       const selectedCountry = e.target.value;
       setBranchInputData(prevState => ({ ...prevState, country: parseInt(selectedCountry) }));

       try {
           const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
           setStateData(response); // Save state data
           setCityData([]); // Reset city data
       } catch (error) {
           console.error('Error fetching state data:', error);
       }
   };

   const handleStateChange = async (e) => {
       const selectedState = e.target.value;
       setBranchInputData(prevState => ({ ...prevState, state: parseInt(selectedState) }));

       try {
           const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
           setCityData(response); // Save city data
       } catch (error) {
           console.error('Error fetching city data:', error);
       }
   };
  
   const handleSubmit = async () => {
       if (!branchInputData.name || !branchInputData.name.trim()) {  // Fix: Add parentheses to trim()
           toast.error('Please Enter Branch Name:', 'error');
           return;
       }
       if (!branchInputData.business_name || !branchInputData.business_name.trim()) {  // Fix: Add parentheses to trim()
           toast.error('Please Enter Business Name:', 'error');
           return;
       }
   
       if (!branchInputData.location || !branchInputData.location.trim()) {
           toast.error('Please Enter Location:', 'error');
           return;
       }
       if (!branchInputData.phone_number_1) {
           toast.error('Please Enter Valid Mobile Number:', 'error');
           return;
       }
       
       if (branchInputData.country.length === 0) {
           toast.error('Please Select Country:', 'error');
           return;
       }

       if (branchInputData.state.length === 0) {
           toast.error('Please Select State:', 'error');
           return;
       }

       if (branchInputData.city.length === 0) {
           toast.error('Please Select City:', 'error');
           return;
       }

       try {
           const response = await postToAPI("FtsBranch/", branchInputData);
           if (response.status) {
               toast.success('Branch Added Successfully', 'success');
               fetchBranchData();
               setBranchInputData(initialInputData);
           } else {
               toast.error('Failed to add Branch');
           }
       } catch (error) {
           console.error('error',error);
       }
   };
   

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>New Branch</Card.Title>
                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter branch name...' 
                                       name='branchName' 
                                       id='branchName'
                                       value = {branchInputData.name}
                                       onChange={e => setBranchInputData({ ...branchInputData, name: e.target.value })}
                                       />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter business name...' 
                                       name='businessName' 
                                       id='businessName' 
                                       value = {branchInputData.business_name}
                                       onChange = {e => setBranchInputData({ ...branchInputData, business_name : e.target.value })}   
                                    />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      placeholder='Enter phone number...' 
                                      name='phone_number_1' 
                                      id='phone_number_1' 
                                      value = {branchInputData.phone_number_1}
                                      onChange = {handleMobileChange}
                                      />
                                </div>
                            </Col>
                            <Col xl={8}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter location...' 
                                       name='location' 
                                       id='location' 
                                       value = {branchInputData.location}
                                       onChange = {e => setBranchInputData({ ...branchInputData, location : e.target.value })}
                                       />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Country</Form.Label>
                                    <Form.Select
                                                                        
                                        name='country'
                                        id='country'
                                        value={branchInputData.country}
                                        onChange={handleCountryChange}
                                    >
                                    <option value="">Select Country</option>
                                        {countryData.map(item => (
                                            <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                        ))}
                                </Form.Select>
                            </div>
                            </Col>
                            <Col sm={6} xl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>State</Form.Label>
                                    <Form.Select
                                                                        
                                        name='state'
                                        id='state'
                                        value={branchInputData.state}
                                        onChange={handleStateChange}
                                    >
                                <option value="">Select State</option>
                                    {stateData.map(item => (
                                        <option key={item.id} value={item.state_id}>{item.state_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                            </Col>
                            <Col sm={6} xl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>City</Form.Label>
                                    <Form.Select
                                                                        
                                        name='city'
                                        id='city'
                                        value={branchInputData.city}
                                        onChange={e => setBranchInputData({ ...branchInputData, city: parseInt(e.target.value) })}

                                        >
                                      <option value="">Select City</option>
                                        {cityData.map(item => (
                                            <option key={item.id} value={item.id}>{item.city_name}</option>
                                        ))}
                                    </Form.Select>
                                    </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST No</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter gst no...' 
                                       name='gstNo' 
                                       id='gstNo'
                                       value = {branchInputData.gst_number}
                                       onChange = {e => setBranchInputData({ ...branchInputData, gst_number : e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN No</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter pan no...' 
                                       name='panNo' 
                                       id='panNo' 
                                       value = {branchInputData.pan_card_number}
                                       onChange={e => setBranchInputData({...branchInputData, pan_card_number : e.target.value })}   
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                                                        
                                        name='tax'
                                        id='tax'
                                        value={branchInputData.tax_id}
                                        onChange={e => setBranchInputData({ ...branchInputData, tax_id: e.target.value })}
                                    >
                                    <option value="">Select Tax</option>
                                        {taxData.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                            
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select  name='taxType' id='taxType'>
                                        <option>Exclusive</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Type</Form.Label>
                                    <Form.Select  name='branchType' id='branchType'>
                                        <option>Self</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                       <Form.Select
                                                                        
                                            name='bankName'
                                            id='bankName'
                                            value={ branchInputData.bank_name}
                                            onChange={e=> setBranchInputData({ ...branchInputData, bank_name: e.target.value })}

                                        >
                                    <option value="">Select Bank Name</option>
                                        {bankData.map(item => (
                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                        ))}
                                        </Form.Select>
                                    </div>                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Account No</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter bank account no...' 
                                       name='bankAccountNo' 
                                       id='bankAccountNo'
                                       value ={branchInputData.bank_account_no}
                                       onChange={e => setBranchInputData({ ...branchInputData, bank_account_no : e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank IFSC Code</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter bank IFSC code...' 
                                       name='bankIFSCCode' 
                                       id='bankIFSCCode' 
                                       value = {branchInputData.bank_ifsc_code}
                                       onChange = {e => setBranchInputData({...branchInputData,bank_ifsc_code:e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className='text-end btn-list'>
                        <Button variant="primary" onClick = {handleSubmit}>Add</Button>{' '}
                        <Button variant="secondary">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
