import React,{useState,useEffect} from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import {getFromAPI,postToAPI,putToAPI,showAlert,formatISODateWithTimezone,handleDateFormat} from '../../../Utils/utils';
import { toast } from 'react-toastify';
import { useBranchAdmin,BranchAdminProvider } from './getBranchAdminData'; // Import the useCustomer hook
import { readFile } from 'xlsx';

export default function NewBranchAdmin() {

    const initialBranchAdminData = {
        email : '',
        firstname : '',
        lastname : 'NA',
        facebook : null,
        linkedin : null,
        phonenumber : null,
        skype : null,
        password : '',
        datecreated : handleDateFormat(formatISODateWithTimezone(new Date())),
        profile_image : null,
        last_ip : null,
        last_login : formatISODateWithTimezone(new Date()),
        last_activity : formatISODateWithTimezone(new Date()),
        last_password_change : formatISODateWithTimezone(new Date()),
        new_pass_key : null,
        new_pass_key_requested : null,
        admin : 1,
        role : 0,
        active : 0,
        default_language : null,
        direction : null,
        media_path_slug : null,
        is_not_staff : 0,
        hourly_rate : 0.0,
        two_factor_auth_enabled : 0,
        two_factor_auth_code : null,
        two_factor_auth_code_requested : formatISODateWithTimezone(new Date()),
        email_signature : null,
        created_branch_id : 0,
        gender : 'Male',
        birth_date : '',
        joining_date : '',
        designation : 'NA',
        address : 'NA',
        alternate_name : null,
        alternate_phonenumber : null,
        alternate_address : null,
        id_proof : null,
        past_work_experience : 'NA',
        show_on_calendar : 0,
        referred_by_name : 'NA',
        referred_by_number : 'NA',
        average_rating : 0.0,
        nature : 'Good',
        check_in_time : "09:00", // 9 AM
        check_out_time : "17:00", // 5 PM        
        week_off_day : 0,
        proof_image : 'NA',
        proof_type : 'NA',
        report_to_id : 0,
        bank_account_number : 'NA',
        bank_account_holder_name : 'NA',
        bank_branch_name : 'NA',
        bank_branch_address : 'NA',
        bank_ifsc_code : 'NA',
        created_user_id : 0,
        last_modified_user_id : 0,
        created_date_time : formatISODateWithTimezone(new Date()),
        last_modified_date_time : formatISODateWithTimezone(new Date()),
        isdeleted : 0,
        reserve_varchar1 : null,
        reserve_varchar2 : null,
        reserve_float1 : 0.0,
        reserve_float2 : 0.0,
        reserve_int1 : 0,
        reserve_int2 : 0,
        reserve_double1 : 0.0,
        reserve_date1 : handleDateFormat(formatISODateWithTimezone(new Date()))
    
    };
    
    const[branchAdminInputData,setBranchAdminInputData] = useState(initialBranchAdminData)
    const[branch,setBranch] = useState([]);
  //  const {fetchBranchAdminData} = useBranchAdmin();
      // Function for handle input for phone_number
      const handleMobileChange = (e) => {
        const {name,value} = e.target;
        //Remove space from the input value
        setBranchAdminInputData((prev) => ({
            ...prev,
            [name] : value.replace(/\+/g,''),
        }));
    };

    useEffect(() => {
        const fetchBranch = async () => {
          try {
            const response = await getFromAPI('FtsBranch/');
            if (response) {
              setBranch(response);
            } else {
              toast.error('Failed to get branch');
            }
          } catch (error) {
            console.error(error);
            toast.error('Failed to get branch');
          }
        };
    
        fetchBranch();
      }, []); // Empty dependency array to run only once when the component mounts
    
    const handleAdd = async() => {
        try{
            if(!branchAdminInputData.firstname)
            {
                toast.error("Please enter the Name:",'error');
                return;
            }
            if(!branchAdminInputData.created_branch_id)
            {
                toast.error("Please select the Branch Name:",'error');
                return;
            }
            if(!branchAdminInputData.email)
            {
                toast.error("Please enter the Email:",'error');
                return;
            }
            if(!branchAdminInputData.password || branchAdminInputData.password.length < 8)
            { 
              toast.error("Please enter valid 8 character password:",'error')
              return; 
            }

            if(!branchAdminInputData.gender)
            {
                toast.error("Please select Gender:",'error');
                return;
            }

            if(!branchAdminInputData.birth_date)
            {
                toast.error("Please select Birth Date:",'error');
                return;
            }

            if(!branchAdminInputData.joining_date)
            {
                toast.error("Please select Joining Date:",'error');
                return;
            }
                        
            const response = await postToAPI('FtsStaff/', branchAdminInputData);
            if(response){
                toast.success('Branch Admin added successfully','success');
                setBranchAdminInputData(initialBranchAdminData);
            //    fetchBranchAdminData();
            }else{
                toast.error('Error adding Branch Admin','error');
            }
        }catch(error){
            toast.error('Email Id Already Exists',"error");
        }
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result); // Directly resolve with the Base64 string
          };
          reader.onerror = reject; // Reject if there's an error
          reader.readAsDataURL(file); // Read the file as a Data URL
        });
      };
    
      const handleFileUpload = async (e) => {
        try {
          const file = e.target.files[0]; // Get the selected file
          if (e.target.id === 'id_proof' && file) {
            const base64PDF = await readFileAsDataURL(file);
            console.log("base64:",base64PDF)
            const base64String = base64PDF.split(',')[1]; // Get the Base64 part
    
            setBranchAdminInputData((prevData) => ({
              ...prevData,
              id_proof: base64String, // Store only the Base64 part
            }));
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      };

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>New Branch Admin</Card.Title>
                    <Form>
                        <Row>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter Name...' 
                                       name='firstname' 
                                       id='firstname' 
                                       value = {branchAdminInputData.firstname}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, firstname: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch</Form.Label>
                                     <Form.Select
                                            
                                            name='created_branch_id'
                                            id='created_branch_id'
                                            value={branchAdminInputData.created_branch_id}
                                            onChange={e => setBranchAdminInputData({ ...branchAdminInputData, created_branch_id: parseInt(e.target.value) })}>
                                            <option value="">Select Branch</option>
                                            {branch.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control 
                                       type="email" 
                                       placeholder='Enter Email...' 
                                       name='emailId' 
                                       id='emailId' 
                                       value = {branchAdminInputData.email}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, email: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder='Enter password...' 
                                        name='password' 
                                        id='password' 
                                        value = {branchAdminInputData.password}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, password: e.target.value })}
                                        />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder='Enter Address...' 
                                        name='address' 
                                        id='address' 
                                        value = {branchAdminInputData.address}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control 
                                       type="number" 
                                       placeholder='Enter mobile number ...' 
                                       name='phonenumber' 
                                       id='phonenumber' 
                                       value={branchAdminInputData.phonenumber}
                                       onChange={handleMobileChange} 
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select  
                                        name='gender' 
                                        id='gender'
                                        value = {branchAdminInputData.gender}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, gender: e.target.value })} 
                                        >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control 
                                        type="date"
                                        placeholder='DD/MM/YYYY'
                                        name='birthday' 
                                        id='birthday' 
                                        value = {branchAdminInputData.birth_date}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, birth_date: e.target.value })} 
                                        />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Date of Join</Form.Label>
                                    <Form.Control 
                                       type="date" 
                                       placeholder='DD/MM/YYYY' 
                                       name='dateofJoin' 
                                       id='dateofJoin' 
                                       value = {branchAdminInputData.joining_date}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, joining_date: e.target.value })} 
                                    />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder='Enter alternate name...' 
                                        name='alternateName' 
                                        id='alternateName'
                                        value = {branchAdminInputData.alternate_name}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, alternate_name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Phone Number</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter alternate phone number...' 
                                       name='alternatePhoneNumber' 
                                       id='alternatePhoneNumber'
                                       value = {branchAdminInputData.alternate_phonenumber}
                                       onChange={handleMobileChange}
                                       />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Address</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter alternate address...' 
                                       name='alternateAddress' 
                                       id='alternateAddress' 
                                       value = {branchAdminInputData.alternate_address}
                                       onChange = {e => setBranchAdminInputData({ ...branchAdminInputData, alternate_address: e.target.value })}   
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Id Proof (PDF)</Form.Label>
                                <Form.Control  
                                    type="file" 
                                    accept="application/pdf" // Only allow PDF files
                                    name="id_proof" 
                                    id="id_proof" 
                                    onChange={e => handleFileUpload(e)}
                                />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className='text-end btn-list'>
                        <Button variant="primary" onClick={handleAdd}>Add</Button>{' '}
                        <Button variant="secondary">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
