import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Table, Form } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table data
import IncomeInvoiceTableData from "./income-invoice-table-data.json";
import IncomePurchaseReturnTableData from "./income-purchase-return-table-data.json";

export default function Income() {
    // datatable
    const [invoiceData] = useState(IncomeInvoiceTableData);
    const [PurchaseReturnData] = useState(IncomePurchaseReturnTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('.mainDatatableIncome')) {
            $('.mainDatatableIncome').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            var dtTopFillter = document.getElementsByClassName("dataTables_wrapper");
            Array.prototype.forEach.call(dtTopFillter, function (el) {
                el.classList.add("dtl-right");
            });
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='custom-top-nav-tab'>
                                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-interest-income-tab" data-bs-toggle="pill" data-bs-target="#pills-interest-income" type="button" role="tab" aria-controls="pills-interest-income" aria-selected="true">Interest Income</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-invoice" type="button" role="tab" aria-controls="pills-invoice" aria-selected="false">Invoice</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-refund-received-tab" data-bs-toggle="pill" data-bs-target="#pills-refund-received" type="button" role="tab" aria-controls="pills-refund-received" aria-selected="false">Refund Received</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-customer-payment-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-payment" type="button" role="tab" aria-controls="pills-customer-payment" aria-selected="false">Customer Payment</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-income-tab" data-bs-toggle="pill" data-bs-target="#pills-income" type="button" role="tab" aria-controls="pills-income" aria-selected="false">Income</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-purchase-return-tab" data-bs-toggle="pill" data-bs-target="#pills-purchase-return" type="button" role="tab" aria-controls="pills-purchase-return" aria-selected="false">Purchase Return</button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-interest-income" role="tabpanel" aria-labelledby="pills-interest-income-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Interest Income</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Saving</td>
                                                                <td>28/12/2022</td>
                                                                <td>Gold</td>
                                                                <td>3,580</td>
                                                                <td>Case</td>
                                                                <td>Invoice</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-invoice" role="tabpanel" aria-labelledby="pills-invoice-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Invoice</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {invoiceData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.srNo}</td>
                                                                    <td>{data.account}</td>
                                                                    <td>{data.date}</td>
                                                                    <td>{data.category}</td>
                                                                    <td>{data.amount}</td>
                                                                    <td>{data.paymentMode}</td>
                                                                    <td>{data.description}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-refund-received" role="tabpanel" aria-labelledby="pills-refund-received-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Refund received</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Saving</td>
                                                                <td>28/12/2022</td>
                                                                <td>Invoice</td>
                                                                <td>320</td>
                                                                <td>Case</td>
                                                                <td>Invoice</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-customer-payment" role="tabpanel" aria-labelledby="pills-customer-payment-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Customer Payment</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Case</td>
                                                                <td>28/12/2022</td>
                                                                <td>Invoice</td>
                                                                <td>320</td>
                                                                <td>Case</td>
                                                                <td>Invoice</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-income" role="tabpanel" aria-labelledby="pills-income-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Income</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Case</td>
                                                                <td>28/12/2022</td>
                                                                <td>Invoice</td>
                                                                <td>320</td>
                                                                <td>Case</td>
                                                                <td>Invoice</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-purchase-return" role="tabpanel" aria-labelledby="pills-purchase-return-tab" tabIndex="0">
                                                    <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                                        <Card.Title className='mb-sm-0'>Purchase Return</Card.Title>
                                                        <div>
                                                            <Form className='d-sm-flex align-items-center'>
                                                                <Form.Select  size="sm">
                                                                    <option>All Time</option>
                                                                    <option value="1">Select One</option>
                                                                    <option value="2">Select Two</option>
                                                                </Form.Select>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <Table responsive bordered className='table-nowrap mainDatatableIncome'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Account</th>
                                                                <th>Date</th>
                                                                <th>Category</th>
                                                                <th>Amount</th>
                                                                <th>Payment Mode</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {PurchaseReturnData.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.srNo}</td>
                                                                    <td>{data.account}</td>
                                                                    <td>{data.date}</td>
                                                                    <td>{data.category}</td>
                                                                    <td>{data.amount}</td>
                                                                    <td>{data.paymentMode}</td>
                                                                    <td>{data.description}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
