import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const ProductContext = createContext();

// Custom hook to use the CustomerContext
export const useProduct = () => useContext(ProductContext);

// Create a Context Provider component
export const ProductProvider = ({ children }) => {
    const [unitData,setUnitData] = useState([])

    const fetchUnitData1 = async () => {
        try {
            const response = await getFromAPI('FtsUnit/');
            setUnitData(response);
        } catch (error) {
            console.error('Error fetching Unit data:', error);
        }
    };

    return (
        <ProductContext.Provider value={{ unitData, fetchUnitData1 }}>
            {children}
        </ProductContext.Provider>
    );
};
