import SweetAlert from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

export const Backend_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Handles the response from an HTTP request.
 * If the response is not okay, it throws an error with the error message from the server.
 * @param {Response} response - The HTTP response object.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function handleResponse(response) {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Request failed');
  }
  return response.json();
}

/**
 * Makes a GET request to an API endpoint with optional query parameters.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} queryParams - Optional query parameters.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function getFromAPI(endpoint, employee_id , queryParams = {}) {
  try {
    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${API_BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;
    
    const response = await fetch(url, {
      method: 'GET',
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

/**
 * Makes a POST request to an API endpoint.
 * @param {string} endpoint - The API endpoint.
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function postToAPI(endpoint, data) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

export async function putToAPI(endpoint, data) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'PUT',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

/**
 * Makes a DELETE request to an API endpoint.
 * @param {string} endpoint - The API endpoint.
 * @returns {Promise<Object>} - A promise that resolves to the JSON response.
 */
export async function deleteFromAPI(endpoint) {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'DELETE',
      //credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

export function showAlert(message, type = 'error', callback, options = {}) {
  let title = 'Error!';
  let icon = 'error';

  if (type === 'success') {
    title = 'Success!';
    icon = 'success';
  } else if (type === 'info') {
    title = 'Info';
    icon = 'info';
  } else if (type === 'warning') {
    title = 'Warning';
    icon = 'warning';
  } else if (type === 'confirm') {
    SweetAlert.fire({
      icon: 'question',
      title: options.title || 'Confirm',
      text: message,
      padding: 0,
      showCancelButton: true,
      confirmButtonColor: options.confirmButtonColor || '#3085d6',
      confirmButtonText: options.confirmButtonText || 'Yes',
      cancelButtonText: options.cancelButtonText || 'No',
      customClass: {
        actions: 'custom-actions', // Add a custom class to the actions container
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'Yes'
        callback(true);
      } else {
        // User clicked 'No' or closed the dialog
        callback(false);
      }
    });
    return;
  }

  SweetAlert.fire({
    icon,
    title,
    text: message,
  });
}

// Add custom styles for extra space
const style = document.createElement('style');
style.innerHTML = `
  .custom-actions {
    margin-bottom: 20px; /* Adjust the space below the buttons */
  }
`;
document.head.appendChild(style);

/**
 * @param {*} date - date object or string
 * @returns - formatted date currently as YYYY-MM-DD
 */
export const handleDateFormat = (date) => {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Ensure the date is valid
  if (isNaN(date.getTime())) {
    console.error('Invalid date format');
    return null;
  }
  // TODO: handle date format based on format set in settings
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  // Currently set format as YYYY-MM-DD, a basic database format
  return `${year}-${month}-${day}`;
};

export const formatISODateWithTimezone = (date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const tzOffset = -date.getTimezoneOffset(); // Timezone offset in minutes
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (num) => String(num).padStart(2, '0');

  // Create timezone string in the format +HH:MM or -HH:MM
  const tzHours = pad(Math.floor(Math.abs(tzOffset) / 60));
  const tzMinutes = pad(Math.abs(tzOffset) % 60);
  const isoString = date.toISOString().slice(0, -1); // Remove the 'Z'

  return `${isoString}${diff}${tzHours}:${tzMinutes}`;
};
