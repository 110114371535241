import React, { useState } from 'react';
import { Card, Form, Row, Col, Table } from 'react-bootstrap';

// table data
import ExpensesTableData from "./profit-loss-expenses-data.json";

export default function ProfitLossData() {
    const [expensesData] = useState(ExpensesTableData);
    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Profit & Loss</Card.Title>
                <Form>
                    <div className='min-sm-w'></div>
                    <Form.Select  size="sm">
                        <option>All Time</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                    </Form.Select>
                </Form>
            </div>

            <Row>
                <Col lg={6}>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Expense</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Expenses</th>
                                    <th>4,842,382.00</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expensesData.ExpensesData.map((data, i) =>
                                    <tr key={i}>
                                        <td>{data.expenses}</td>
                                        <td>{data.expensesAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot className='table-light'>
                                <tr>
                                    <th>Renewal Fees-yes Bank</th>
                                    <th>-4,753,954.73</th>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap mt-auto mb-0'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Total Expenses</th>
                                        <th>88,427.27</th>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                    </div>
                </Col>

                <Col lg={6} className='mt-5 mt-lg-0'>
                    <div className="d-flex flex-column h-100">
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Income</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                        </Table>
                        <Table responsive className='border td-end table-nowrap'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Gross Profit</th>
                                    <th>248,989.27</th>
                                </tr>
                                <tr>
                                    <th>Profit - Loss Income</th>
                                    <th>-160,562.00</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Discount</td>
                                    <td>-160,562.00</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="mt-auto">
                            <Table responsive className='border td-end table-nowrap mt-auto mb-0'>
                                <thead className='table-light'>
                                    <tr>
                                        <th>Total Income</th>
                                        <th>88,427.27</th>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
