import React from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Dropdown } from 'react-bootstrap';

// icons
import Icon from '@mdi/react';
import { mdiCash, mdiDotsVertical, mdiBank } from '@mdi/js'

export default function PaymentBasedCollection() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-md-0'>Payment Based Collection</Card.Title>
                                            <Form>
                                                <div className='min-sm-w'>
                                                    <Form.Select  size="sm">
                                                        <option>Last Month</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Form>
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <Card className='border shadow-none mb-md-0'>
                                                    <Card.Body>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <div className='avatar avatar-xxl bg-soft-primary text-primary'>
                                                                    <Icon path={mdiCash} className='avatar-icon' />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-4 my-2">
                                                                <h2 className='mb-1'>493006</h2>
                                                                <p className='fs-18 mb-0'>Cash Collection</p>
                                                            </div>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                                    <Icon path={mdiDotsVertical} className='icon-size-24' />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end">
                                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={6}>
                                                <Card className='border shadow-none mb-0'>
                                                    <Card.Body>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <div className='avatar avatar-xxl bg-soft-success text-success'>
                                                                    <Icon path={mdiBank} className='avatar-icon' />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-4 my-2">
                                                                <h2 className='mb-1'>196083</h2>
                                                                <p className='fs-18 mb-0'>Bank Collection</p>
                                                            </div>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                                    <Icon path={mdiDotsVertical} className='icon-size-24' />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu align="end">
                                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
