import React from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import ComboBox from './ComboBox';

const ListViewTable = ({ initialRows, columnsConfig, onAddRow, handleOnChange }) => {

  return (
    <div>
      <Table size='sm' bordered className='table-nowrap'>
        <thead className='table-light'>
          <tr>
            {columnsConfig.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {initialRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnsConfig.map((col, colIndex) => (
                <td key={colIndex} className={col.columnClass}>
                  {col.type === 'autoIncrement' && (
                    rowIndex + 1 // Auto-incrementing serial number
                  )}
                  {col.type === 'checkbox' && (
                    <Form.Check
                      type="checkbox"
                      checked={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.checked)
                      }
                    />
                  )}
                  {col.type === 'text' && (
                    <Form.Control
                      size="sm"
                      type="text"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                      onKeyPress={col.onKeyPress ? col.onKeyPress : undefined}
                    />
                  )}
                  {col.type === 'number' && (
                    <Form.Control
                      size="sm"
                      type="number"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                    />
                  )}
                  {col.type === 'select' && (
                    <Form.Control
                      size="sm"
                      as="select"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                    >
                      {col.options.map((option, optIndex) => (
                        <option key={optIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                  {col.type === 'react-select' && (
                    <Select
                      className='w-auto'
                      value={col.options && col.options.find(option => option.value === row[col.field]) || ''}
                      onChange={(selectedOption) => handleOnChange(rowIndex, col.field, selectedOption ? selectedOption.value : '')}
                      options={col.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onKeyDown={(e) => col.onKeyPress ? col.onKeyPress: undefined} 
                    />
                  )}
                  {col.type === 'comboBox' && (
                    <ComboBox 
                      slectOptions={col.options} 
                      defualtValue={col.options && col.options.find(option => option.id === row[col.field]) || ''} 
                      onChange={(id) => handleOnChange(rowIndex, col.field, id)}  
                      handleKeyDown={col.KeyPressFunction ? col.KeyPressFunction : undefined} 
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant='light btn-sm mb-3' onClick={onAddRow}>
        <Icon path={mdiPlus} className="btn-icon me-1" />Add New Row
      </Button>
    </div>
  );
};

export default ListViewTable;
