import React, { useState,useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {formatISODateWithTimezone, getFromAPI,postToAPI,putToAPI} from '../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import SettingsListData from "./settings-data.json";
 
// Icon
import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';

export default function Settings() {
    const [settingsData] = useState(SettingsListData);
    const [modules, setModules] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState(''); // State to track selected radio button
    const [checked, setChecked] = useState(); // State to manage the checkbox checked status
    const [consumableStockChecked, setConsumableStockChecked] = useState(false); // Use this state to control checkbox
    const [productVisibility, setProductVisibility] = useState(null); // New state for fetched value

    const [optionData, setOptionData] = useState({
        created_branch_id: 0, 
        created_user_id: 0,  
        last_modified_user_id: 0, 
        autoload : 1
    });

      // Fetch the initial option data
      const fetchOptionData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_on_floor_in_store');
            setProductVisibility(response[0].value)
            if (response[0].value === "1") setSelectedRadio("in-store-check-radio");
            else if (response[0].value === "2") setSelectedRadio("on-floor-check-radio");
            else if (response[0].value === "0") setSelectedRadio("both-check-radio");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // Fetch initial data for consumable stock checkbox
    const fetchCheckedOption = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_consumable_stock');
            if (response[0]?.value === "1") {
                setConsumableStockChecked(true); // If the response value is "1", checkbox will be checked
            } else {
                setConsumableStockChecked(false); // If the response value is not "1", checkbox will be unchecked
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    useEffect(() => {
        fetchOptionData();
        fetchCheckedOption();
    }, []);

    const fetchModules = async () => {
        try {
            const response = await getFromAPI('FtsModules/');
            setModules(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchModules();
    }, []);

    // Update module's isactive status in the backend
    const updateModule = async (moduleId, isActiveStatus) => {
        try {
            await putToAPI(`FtsModules/${moduleId}/`, { active: isActiveStatus });
        } catch (error) {
            console.error("Failed to update module:", error);
        }
    };

    // Handle checkbox change for modules
    const onCheckboxChange = (index) => {
        const updatedModules = [...modules];
        updatedModules[index].active = updatedModules[index].active === 1 ? 0 : 1;
        setModules(updatedModules);

        // Call updateModule to update isactive in the backend
        updateModule(updatedModules[index].id, updatedModules[index].active);
    };


    // Example of how the backend function could be called
    const updateOptionInBackend = async (data) => {
        try {
            // Ensuring value is a string before sending it to the backend
            await postToAPI('update_or_create_option/',data);
            toast.success('Option Saved successfully','Success');
        } catch (error) {
            console.error('Error updating option:', error);
        }
    };

    const handleRadioChange = async (e) => {
        const selectedRadioId = e.target.id;
        setSelectedRadio(selectedRadioId); // Update selected radio button state instantly
    
        // Prepare the data based on the selected radio button
        let name = 'product_on_floor_in_store';
        let value = productVisibility;
    
        if (selectedRadioId === 'on-floor-check-radio') value = '2';
        else if (selectedRadioId === 'in-store-check-radio') value = '1';
        else if (selectedRadioId === 'both-check-radio') value = '0';
    
        const currentDateTime = new Date();
        const data = {
            name,
            value,
            autoload: 1,
            created_branch_id: optionData.created_branch_id,
            created_user_id: optionData.created_user_id,
            created_date_time: formatISODateWithTimezone(currentDateTime),
            last_modified_date_time: formatISODateWithTimezone(currentDateTime),
            last_modified_user_id: optionData.last_modified_user_id,
        };
    
        // Update the backend with the new option data
        try {
            await updateOptionInBackend(data);
        } catch (error) {
            console.error('Error updating option:', error);
        }
    };

      // Handle checkbox change and update backend
      const handleCheckboxChange = async (e) => {
        const isChecked = e.target.checked;
        setConsumableStockChecked(isChecked); // Update checkbox state

        const name = 'product_consumable_stock';
        const value = isChecked ? '1' : '0'; // Set value based on checkbox state

        // Prepare data to update in the backend
        const data = {
            name,
            value,
            autoload: 1,
            created_branch_id: 0,  // Use actual branch ID if available
            created_user_id: 0,    // Use actual user ID if available
            created_date_time: new Date().toISOString(),
            last_modified_date_time: new Date().toISOString(),
            last_modified_user_id: 0 // Use actual user ID if available
        };

        // Send the updated data to the backend
        try {
            await postToAPI('update_or_create_option/', data);
            toast.success('Option Saved successfully','Success');
        } catch (error) {
            console.error('Error updating option:', error);
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col xl={6}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Account & Support Settings</Card.Title>
                                        <Row>
                                            {settingsData.supportSettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Masters</Card.Title>
                                        <Row>
                                            {settingsData.mastersLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Privacy & Security Settings</Card.Title>
                                        <Row>
                                            {settingsData.securitySettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Manage Earning Settings</Card.Title>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check
                                                        type={type}
                                                        id={`manage-earning-${type}`}
                                                        label={`Customise Provider Earning`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl={6}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Product & Service Helper Masters</Card.Title>
                                        <Row>
                                            {settingsData.helperMastersLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>GST Settings</Card.Title>
                                        <Row>
                                            {settingsData.gstSettingsLink.map((linkname, i) =>
                                                <Col md={6} key={i}>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <Link to={linkname.cardLink} className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-normal mb-0 me-3'>{linkname.id}.{linkname.settingLinkName}</h5>
                                                                <Icon path={mdiArrowRightThin} className='icon-size-30 text-dark' />
                                                            </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Modules</Card.Title>
                                        <Form>
                                            <Row sm={3} md={3} lg={4} xl={5} xxl={6} className='row-cols-2'>
                                                {modules.map((data, i) =>
                                                    <Col key={i}>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`default-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    type={type}
                                                                    id={`modules-${data.id}`}
                                                                    label={data.module_name}
                                                                    checked={data.active===1}
                                                                    onChange={() => onCheckboxChange(i)}
                                                                    />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                )}
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={7} xxl={8}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Invoice</Card.Title>

                                        <Form>
                                            <Row>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Invoice Type</Form.Label>
                                                        <Form.Select  name='setDefaultInvoiceType' id='setDefaultInvoiceType'>
                                                            <option>Service</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Invoice Payment Mode</Form.Label>
                                                        <Form.Select  name='setDefaultInvoicePaymentMode' id='setDefaultInvoicePaymentMode'>
                                                            <option>Cash</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Provider Of Invoice</Form.Label>
                                                        <Form.Select  name='setDefaultProviderOfInvoice' id='setDefaultProviderOfInvoice'>
                                                            <option>Select Provider</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Set Default Customer Of Invoice</Form.Label>
                                                        <Form.Select  name='setDefaultCustomerOfInvoice' id='setDefaultCustomerOfInvoice'>
                                                            <option>NFS Customer, 9825596868</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Thank You Message</Form.Label>
                                                        <Form.Select  name='thankYouMessage' id='thankYouMessage'>
                                                            <option>NO</option>
                                                            <option value="1">Yes</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6} xxl={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Allow Negative Stock Of Invoice</Form.Label>
                                                        <Form.Select  name='allowNegativeStockOfInvoice' id='allowNegativeStockOfInvoice'>
                                                            <option>Yes</option>
                                                            <option value="1">No</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xl={5} xxl={4}>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Products</Card.Title>
                                        <Form>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`on-floor-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`On Floor`}
                                                                checked={selectedRadio === "on-floor-check-radio"}
                                                                onChange={handleRadioChange} // Handle radio button change
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item me-5'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`in-store-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`In Store`}
                                                                checked={selectedRadio === "in-store-check-radio"}
                                                                onChange={handleRadioChange} // Handle radio button change
                                                                
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['radio'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`both-check-${type}`}
                                                                name="products-radio-check"
                                                                label={`Both`}
                                                                checked={selectedRadio === "both-check-radio"}
                                                                onChange={handleRadioChange} // Handle radio button change
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>

                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`detail-in-product-Unchecked-${type}`}
                                                                label={`Show Weight Detail in Product`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`consumable-stock-Unchecked-${type}`}
                                                                label={`Consumable Stock`}
                                                                checked={consumableStockChecked} // Dynamically set the checked value based on state
                                                                onChange={handleCheckboxChange} // Handle checkbox change
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        </Form>

                                        <Card.Title>Transfer Order</Card.Title>
                                        <Form>
                                            <ul className='list-inline mb-0'>
                                                <li className='list-inline-item me-5'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`detail-in-product1-Unchecked-${type}`}
                                                                label={`Show Weight Detail in Product`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                                <li className='list-inline-item'>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`default-${type}`} className="mb-3">
                                                            <Form.Check
                                                                type={type}
                                                                id={`consumable-stock-Unchecked1-${type}`}
                                                                label={`Consumable Stock`}
                                                            />
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>Accounting</Card.Title>
                                        <Form>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Ledger Options</Form.Label>
                                                        <Form.Select  name='ledgerOptions' id='ledgerOptions'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Show Category Option</Form.Label>
                                                        <Form.Select  name='showCategoryOption' id='showCategoryOption'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Show Payment Type Option</Form.Label>
                                                        <Form.Select  name='showPaymentTypeOption' id='showPaymentTypeOption'>
                                                            <option>No</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
