import React, { useState, useEffect } from 'react';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form, Table, Button, Nav, Modal } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { postToAPI, getFromAPI, deleteFromAPI, putToAPI, showAlert } from '../../Utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchBranchData, fetchCutsomerData, fetchServiceCategory } from '../../Utils/function_list';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import ServicesLeftData from "./services-left-data.json";

// react-select
import Select from 'react-select'

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus, mdiAirplaneCog, mdiSquareEditOutline, mdiTrashCanOutline, mdiCardAccountDetailsOutline } from '@mdi/js';
import FormModal from '../../Utils/FormModal';


export default function Services() {
    // multi-select
    const initialInputData = {
        name: '',
        sac: '',
        duration: '',
        category: 0,
        earning: 0.0,
        description: '',
        branch_id: 0,
    };

    const [inputData, setInputData] = useState(initialInputData)
    const [serviceData, setServiceData] = useState([]);
    const [serviceCategory, setServiceCategoryData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editServiceId, setEditServiceId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [branchServiceDataMap, setBranchServiceDataMap] = useState({}); // To store branch data for each service

    const fetchServices = async () => {
        try {
            const response = await getFromAPI('FtsService/');
            setServiceData(response); // Assuming response contains an array of service objects
        } catch (error) {
            console.error('Error fetching service data:', error);
        }
    };


    const fetchBranchServiceData = async (id) => {
        try {
            const response = await getFromAPI(`FtsServiceBranchDetails/?service_id=${id}`);

            // Assuming response is an array and the first item contains the branch data
            setBranchServiceDataMap(prevState => ({
                ...prevState,
                [id]: response[0] // Store the first item in the response
            }));

        } catch (error) {
            console.error("Error Fetching Branch Service Data:", error);
        }
    };



    // useEffect to fetch branch service data for each service
    useEffect(() => {
        if (serviceData.length > 0) {
            serviceData.forEach(service => {
                fetchBranchServiceData(service.id); // Fetch branch data for each service
            });
        }
    }, [serviceData]); // Run this effect whenever serviceData changes

    useEffect(() => {
        fetchServices(); // Fetch the services when the component mounts
        fetchServiceCategory(setServiceCategoryData)
        fetchBranchData(setBranchData); // Fetch branch data

    }, []);

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const vendorId = this.getAttribute('data-id');

                // Trigger click on the "View Vendor" tab
                const viewVendorTab = document.getElementById("pills-view-services-tab");
                if (viewVendorTab) {
                    viewVendorTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [fetchServices]);


    // datatable
    const [ServicesLeftListData] = useState(serviceData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (serviceData.length > 0 && !$.fn.dataTable.isDataTable('#mainDatatableViewServices')) {
            $('#mainDatatableViewServices').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, [serviceData]);

    // Handle Delete Customer Entry
    const handleDeleteService = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Customer?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDelete(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Customer:', error);
            toast.error('Error deleting Customer', 'error');
        }
    };

    const handleEdit = async (id, e) => {
        e.preventDefault();
        try {
            const response = await getFromAPI(`FtsService/${id}/`);

            // Assuming `response` is an object, not an array
            const service = response.find(item => item.id === id);

            if (service) {
                const branchId = branchServiceDataMap[service.id].branch_id

                const branchCategoryData = await getFromAPI(`FtsBranch/?id=${branchId}`)
                let branchName = '';
                if (branchCategoryData) {
                    branchName = branchCategoryData[0].name;
                }

                const categoryData = await getFromAPI(`FtsServiceCategory/?id=${service.category}`)
                let categoryName = '';
                if (categoryData) {
                    categoryName = categoryData[0].name;
                }

                setInputData({
                    name: service.name,
                    category: categoryName,
                    sac: service.sac_code,
                    duration: branchServiceDataMap[service.id].duration,
                    price: branchServiceDataMap[service.id].price,
                    earning: branchServiceDataMap[service.id].earning,
                    description: branchServiceDataMap[service.id].description,
                    branch_id: branchName, // Set the parsed branch options
                });
                setEditServiceId(id);
                setShowFormModal(true);
            } else {
                toast.error('Failed to fetch service details');
            }
        } catch (error) {
            console.error('Error fetching service details:', error);
            toast.error('An unexpected error occurred');
        }
    };


    const handleSubmit = async () => {
        try {
            if (!inputData.name) {
                toast.error('Please Enter Name:', 'error')
                return;
            }
            if (!inputData.price) {
                toast.error('Please Enter Price:', 'error')
                return
            }
            if (!inputData.category || inputData.category.length === 0) {
                toast.error('Please Select Service Category:', 'error')
                return
            }
            if (!inputData.sac) {
                toast.error('Please Enter SAC:', 'error');
                return;
            }
            if (!inputData.description) {
                toast.error('Please enter description:', 'error');
                return
            }
            if (!inputData.duration) {
                toast.error('Please enter duration in minute:', 'error')
                return
            }
            if (!inputData.earning) {
                toast.error('Please enter earning:', 'error')
                return
            }
            if (!inputData.branch_id || inputData.branch_id.length == 0) {
                toast.error('Please select Branch:', 'error')
                return
            }

            const santizedData = {
                name: inputData.name,
                category: parseInt(inputData.category),
                sac: inputData.sac,
                duration: parseInt(inputData.duration),
                price: parseFloat(inputData.price),
                earning: parseInt(inputData.earning),
                description: inputData.description,
                branch_id: inputData.branch_id,
            }

            if (editServiceId) {
                // Edit mode
                const response = await putToAPI(`FtsService/${editServiceId}/`, santizedData);
                if (response.status) {
                    toast.success('Service Updated Successfully', 'success');
                } else {
                    toast.error('Failed to update service');
                }
            } else {
                // Add mode
                const response = await postToAPI("create_service/", santizedData);
                if (response) {
                    toast.success('Service Added Successfully', 'success');
                    setInputData(initialInputData)
                } else {
                    toast.error('Failed to add service');
                }
            }
            setShowEditModal(false);
            fetchServices();
        } catch (error) {
            console.error('Error saving service:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteFromAPI(`FtsService/${id}/`);
            if (response.status) {
                toast.success('Service Deleted Successfully', 'success');
                fetchServices(); // Refresh the service data
            } else {
                toast.error('Failed to delete service');
            }
        } catch (error) {
            console.error('Error deleting service:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const filteredCustomerData = serviceData.filter(service => {
        // Convert search term to lower case
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        // Check if either name or service_category includes the search term
        return service.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            (service.category && service.category.toLowerCase().includes(lowerCaseSearchTerm));
    });


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddServiceTabClick = () => {
        setInputData({
            name: null,
            category: null,
            sac: null,
            duration: null,
            price: null,
            earning: null,
            description: null,
            branch_id: null,
        });
        setEditServiceId(null); // Reset editServiceId to null to switch to Add mode

    };

    const [serviceCategories, setServiceCategories] = useState({});

    // Function to fetch category name by ID
    const getServiceCategoryName = async (categoryId) => {
        try {
            const response = await getFromAPI(`FtsServiceCategory/?id=${categoryId}`);
            return response && response[0] ? response[0].name : '';
        } catch (error) {
            console.error('Error fetching category:', error);
            return '';
        }
    };
    // useEffect to fetch customer data and service categories
    useEffect(() => {
        fetchCutsomerData(); // Fetch initial customer data

        // Fetch category names for each customer once data is available
        const fetchCategories = async () => {
            const categories = {};
            for (const customer of serviceData) {
                const categoryName = await getServiceCategoryName(customer.category);
                categories[customer.category] = categoryName; // Cache category names
            }
            setServiceCategories(categories); // Set all resolved categories
        };

        if (serviceData.length > 0) {
            fetchCategories(); // Trigger category fetching when data is loaded
        }
    }, [serviceData]); // Runs whenever `customerData` changes

    // Edit Service modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setInputData({ ...inputData, [field]: value });
    };
    const rows = [
        {
            columns: [
                { size: { lg: 6, xl: 4 }, label: 'Name', type: 'text', name: 'name', id: 'name', placeholder: 'Enter service name...', value: inputData.name, onChange: e => setInputData({ ...inputData, name: e.target.value }), errormsg: '', },
                {
                    size: { lg: 6, xl: 5 }, label: 'Service Category', type: 'selectwithbtn', name: 'category', id: 'serviceCategory', value: inputData.category, errormsg: '',
                    options: [
                        { value: '', label: 'Select Service Category' },
                        ...serviceCategory.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { sm: 6, lg: 6, xl: 3 }, label: 'SAC', type: 'text', name: 'sac', id: 'sac', placeholder: 'Enter SAC...', value: inputData.sac, onChange: e => setInputData({ ...inputData, sac: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 6, xl: 3 }, label: 'Duration (Min)', type: 'text', name: 'duration', id: 'duration', placeholder: 'Enter duration...', value: inputData.duration, onChange: e => setInputData({ ...inputData, duration: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 3, xl: 2 }, label: 'Price (₹)', type: 'text', name: 'price', id: 'price', placeholder: 'Enter Price...', value: inputData.earning, onChange: e => setInputData({ ...inputData, earning: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 4, xl: 2 }, label: 'Earning (%)', type: 'text', name: 'earning', id: 'earning', placeholder: 'Enter Earning...', value: inputData.price, onChange: e => setInputData({ ...inputData, price: e.target.value }), errormsg: '', },
                {
                    size: { lg: 5 }, label: 'Branch', type: 'select', name: 'branch_id', id: 'branch_id', value: inputData.branch_id, onChange: e => setInputData({ ...inputData, branch_id: e.target.value }), errormsg: '',
                    options: [
                        { value: '', label: 'Select Branch' },
                        ...branchData.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { sm: 12 }, label: 'Description', type: 'textarea', name: 'description', formrow: '3', id: 'description', placeholder: 'Type description...', value: inputData.description, onChange: e => setInputData({ ...inputData, description: e.target.value }), errormsg: '', },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Save Changes", onClick: handleSubmit },
        { variant: "secondary", text: "Close", onClick: () => setShowEditModal(false) },
    ]

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                        className=''
                                                        type="text"
                                                        placeholder="Search service..."
                                                        name='serviceSearch'
                                                        id='serviceSearch'
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-services-tab" data-bs-toggle="pill" data-bs-target="#pills-view-services" type="button" role="tab" aria-controls="pills-view-services" aria-selected="true">
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Services
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-add-service-tab" data-bs-toggle="pill" data-bs-target="#pills-add-service" type="button" role="tab" aria-controls="pills-add-service" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Add Service
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-manage-rate-card-tab" data-bs-toggle="pill" data-bs-target="#pills-manage-rate-card" type="button" role="tab" aria-controls="pills-manage-rate-card" aria-selected="false">
                                                            <Icon path={mdiCardAccountDetailsOutline} className="btn-icon me-1" />Manage Rate Card
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-import-service-tab" data-bs-toggle="pill" data-bs-target="#pills-import-service" type="button" role="tab" aria-controls="pills-import-service" aria-selected="false">
                                                            <Icon path={mdiAirplaneCog} className="btn-icon me-1" />Import Service
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filteredCustomerData && filteredCustomerData.length > 0 ? (
                                                    filteredCustomerData.map((data, i) => (
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                            <Nav.Link className={i === 0 ? "active" : ""}>
                                                                <h5 className='product-tab-title'>
                                                                    {serviceCategories[data.category]}
                                                                </h5>
                                                                <p className='product-tab-sub-title'>{data.name}</p>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))
                                                ) : (
                                                    <p>No data available</p>
                                                )}

                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-services" role="tabpanel" aria-labelledby="pills-view-services-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>View Services</Card.Title>

                                                <Table responsive bordered className="table-nowrap" id="mainDatatableViewServices">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Service Details</th>
                                                            <th>Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {serviceData.length > 0 ? (
                                                            serviceData.map((service, index) => (
                                                                <tr key={index}>
                                                                    <td>{service.name}</td>
                                                                    <td>{branchServiceDataMap[service.id] ? branchServiceDataMap[service.id].price : 'Loading...'}</td>

                                                                    <td className="py-1">
                                                                        <div className="d-flex">
                                                                            <Button variant="soft-info p-0 me-3" onClick={(e) => handleEdit(service.id, e)} >
                                                                                <div className='avatar avatar-sm'>
                                                                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                                                                </div>
                                                                            </Button>
                                                                            <Button variant="soft-danger p-0" onClick={() => handleDeleteService(service.id)}>
                                                                                <div className='avatar avatar-sm'>
                                                                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                                                                </div>
                                                                            </Button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3" className="text-center">No services available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </ Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-add-service" role="tabpanel" aria-labelledby="pills-add-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Add Service</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter your name..."
                                                                    name='yourName'
                                                                    id='yourName'
                                                                    value={inputData.name}
                                                                    onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3 d-flex">
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label className='z-1'>Service Category</Form.Label>
                                                                    <Form.Select

                                                                        name='serviceCategory'
                                                                        id='serviceCategory'
                                                                        value={inputData.category}
                                                                        onChange={e => setInputData({ ...inputData, category: e.target.value })}
                                                                        isClearable
                                                                    >
                                                                        <option value="">Select Service Category</option>
                                                                        {serviceCategory.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>

                                                                </div>

                                                                <Button variant="light" className="custom-form-input-btn ms-2 mt-2">
                                                                    <Icon path={mdiPlus} size={1} />
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>SAC</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter SAC..."
                                                                    name='sac'
                                                                    id='sac'
                                                                    value={inputData.sac}
                                                                    onChange={e => setInputData({ ...inputData, sac: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Duration(Min)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter duration..."
                                                                    name='durationMin'
                                                                    id='durationMin'
                                                                    value={inputData.duration}
                                                                    onChange={e => setInputData({ ...inputData, duration: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Price (₹)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Price..."
                                                                    name='price'
                                                                    id='price'
                                                                    value={inputData.price}
                                                                    onChange={e => setInputData({ ...inputData, price: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Earning(%)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Earning..."
                                                                    name='Earning'
                                                                    id='Earning'
                                                                    value={inputData.earning}
                                                                    onChange={e => setInputData({ ...inputData, earning: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Description</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={4}
                                                                    placeholder='Type description...'
                                                                    name='description'
                                                                    id='description'
                                                                    value={inputData.description}
                                                                    onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label className='z-1'>Branch</Form.Label>
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    name='branch_id'
                                                                    id='branch_id'
                                                                    value={inputData.branch_id}
                                                                    onChange={e => setInputData({ ...inputData, branch_id: e.target.value })}
                                                                    isClearable
                                                                >
                                                                    <option value="">Select Branch</option>
                                                                    {branchData.map(item => (
                                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </Form.Select>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary" type="submit" onClick={handleSubmit}>Add</Button>
                                                    <Button variant="secondary" type="button">Cancel</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-manage-rate-card" role="tabpanel" aria-labelledby="pills-manage-rate-card-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Manage Rate Card</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Service Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter service name..."
                                                                    name='serviceName'
                                                                    id='serviceName'
                                                                    value={inputData.serviceName}
                                                                    onChange={e => setInputData({ ...inputData, serviceName: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Current Rate</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter current rate..."
                                                                    name='currentRate'
                                                                    id='currentRate'
                                                                    value={inputData.price}
                                                                    onChange={e => setInputData({ ...inputData, price: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>New Rate</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter new rate..."
                                                                    name='newRate'
                                                                    id='newRate'
                                                                    value={inputData.price}
                                                                    onChange={e => setInputData({ ...inputData, price: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary" type="submit">Update</Button>
                                                    <Button variant="secondary" type="button">Cancel</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-import-service" role="tabpanel" aria-labelledby="pills-import-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Import Services</Card.Title>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group controlId="formFileImportProducts" className="mb-3">
                                                            <Form.Label>Select File</Form.Label>
                                                            <Form.Control type="file" name='importProducts' />
                                                        </Form.Group>
                                                        <Row className='justify-content-center'>
                                                            <Col xl={8} className="text-center">
                                                                <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                                                                <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your service list. Once you have selected a file to upload, your service list will be imported.
                                                                    Duplicate services(based on service name & service category) are not created by this process, so you can correct errors and
                                                                    upload the same file more than once, if necessary.</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary">Download Sample</Button>{' '}
                                                    <Button variant="success">Import</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* From Modal */}
                <FormModal title="Edit Service" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />

                <Footer />
            </div>
        </>
    )
}
