import React from 'react';
import Footer from '../../layout/Footer';
import TradingAccountData from './TradingAccountData';
import ProfitLossData from './ProfitLossData';
import BalanceSheetData from './BalanceSheetData';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiFinance, mdiSwapVerticalBold, mdiScaleBalance, mdiPrinter } from '@mdi/js';

export default function TradingAccount() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <div className="custom-top-nav-tab mb-3 mb-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-trading_account-tab" data-bs-toggle="pill" data-bs-target="#pills-trading_account" type="button" role="tab" aria-controls="pills-trading_account" aria-selected="true">
                                                            <Icon path={mdiFinance} className="btn-icon me-1" />Trading Account
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-profit-loss-tab" data-bs-toggle="pill" data-bs-target="#pills-profit-loss" type="button" role="tab" aria-controls="pills-profit-loss" aria-selected="false">
                                                            <Icon path={mdiSwapVerticalBold} className="btn-icon me-1" />Profit and Loss
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-balance-sheet-tab" data-bs-toggle="pill" data-bs-target="#pills-balance-sheet" type="button" role="tab" aria-controls="pills-balance-sheet" aria-selected="false">
                                                            <Icon path={mdiScaleBalance} className="btn-icon me-1" />Balance Sheet
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <Button variant="secondary"><Icon path={mdiPrinter} className="btn-icon me-1" /> Print</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-trading_account" role="tabpanel" aria-labelledby="pills-trading_account-tab" tabIndex="0">
                                                <TradingAccountData />
                                            </div>
                                            <div className="tab-pane fade" id="pills-profit-loss" role="tabpanel" aria-labelledby="pills-profit-loss-tab" tabIndex="0">
                                                <ProfitLossData />
                                            </div>
                                            <div className="tab-pane fade" id="pills-balance-sheet" role="tabpanel" aria-labelledby="pills-balance-sheet-tab" tabIndex="0">
                                                <BalanceSheetData />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
