import React, { useState } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

// react-select
import Select from 'react-select';

export default function GstReports() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "BD Enterprise", label: "BD Enterprise" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-md-0'>GST Report</Card.Title>
                                            <Form>
                                                <div className='min-sm-w'>
                                                    <Form.Select  size="sm">
                                                        <option>Last Month</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Form>
                                        </div>

                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>Time Duration</Form.Label>
                                                    <Form.Select  name='timeDuration' id='timeDuration'>
                                                        <option>All Time</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>GST Type</Form.Label>
                                                    <Form.Select  name='gstType' id='gstType'>
                                                        <option>Report_R-3</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>GST Tax</Form.Label>
                                                    <Form.Select  name='gstTax' id='gstTax'>
                                                        <option>All</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>GST Tax</Form.Label>
                                                    <Form.Select  name='gstTax1' id='gstTax1'>
                                                        <option>All</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>GST Report</Form.Label>
                                                    <Form.Select  name='gstTax' id='gstTax'>
                                                        <option>Output-Sal/Srt/Dcrt</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="custom-form-input">
                                                    <Form.Label className='z-1'>Branch</Form.Label>
                                                    <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>GST Report Description</Card.Title>
                                        <h5 className='fw-normal mb-3 fs-17'>All Time | Report_R-3 | All | All | Output-Sal/Srt/Dcrt</h5>
                                        <p className='text-muted mb-3'>GST R3 contains the monthly summary of sales and purchases along with tax liability. It basically consists of two parts i.e. Part A and Part B. Part A of the return is electronicallygenerated on the basis of information furnished through returns in GST
                                            R1 and GST R2, Whereas Part B contains the tax liability, interest, and penalty paid. It is considered to be important as it will only tell the GST liability of a person for a particular month. This tax liability a is person compelled to discharge.</p>
                                        <div className="div btn-list text-end">
                                            <Button variant="success">Export</Button>
                                            <Button variant="secondary">Cancel</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
