import React, { useState } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import ServiceCategoryData from "./service-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import FormModal from '../../../Utils/FormModal';

export default function ViewServiceCategory() {
  // table data
  const [serviceCategoryTableData] = useState(ServiceCategoryData);

  // input value
  const [data, setData] = useState({
    serviceCategoryName: "Service Category", serviceDescription: "Service Category"
  });
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  // Edit modal
  const [formModalShow, setShowFormModal] = useState(false);
  const handleServCategoryEdit = () => setShowFormModal(true);
  const handleFormModalClose = () => setShowFormModal(false);

  const handleInputChange = (index, field, value) => {
    setData({ ...data, [field]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFormModal(false);
  };

  const rows = [
    {
      columns: [
        { size: { lg: 12 }, label: 'Service Category Name', type: 'text', name: 'serviceCategoryName', id: 'serviceCategoryName', placeholder: 'Enter service category name...', value: data.serviceCategoryName, onChange: onChange, errormsg: '', },
        { size: { lg: 12 }, label: 'Service Description', type: 'textarea', rows: 4, name: 'serviceDescription', id: 'serviceDescription', placeholder: 'Type service description...', value: data.serviceDescription, onChange: onChange, errormsg: '', },
      ]
    }
  ];
  const Buttons = [
    { variant: "primary", text: "Update", onClick: handleFormModalClose },
    { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
  ]
  
  return (
    <>
      <Card className='right-content-details'>
        <Card.Body>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <Card.Title className='mb-0'>View Service Category</Card.Title>
            <div className='d-flex'>
              <div className="btn-list">
                <Button variant="soft-info p-0" onClick={handleServCategoryEdit}>
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
                <Button variant="soft-danger p-0">
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
              </div>
            </div>
          </div>
          <Table responsive className='table-lg border table-nowrap mb-0'>
            <tbody>
              {serviceCategoryTableData.serviceCategoryTable.map((data, i) =>
                <tr key={i}>
                  <th>{data.tableheading1}</th>
                  <td>{data.tablesubtext1}</td>
                  <th>{data.tableheading2}</th>
                  <td>{data.tablesubtext2}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* From Modal */}
      <FormModal title="Edit Service Category" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    </>
  )
}
