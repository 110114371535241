import React from 'react';
import Footer from '../../layout/Footer';
import CreateQuote from './CreateQuote';
import { Container, Row, Col, Card } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiFileDocumentOutline, mdiListBoxOutline } from '@mdi/js';
import QuoteList from './QuoteList';
import QuoteDraftLits from './QuoteDraftLits';

export default function Quote() {
    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
    };

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center mb-0'>
                                            <Card.Title className='mb-0' id="cardTitle">Quote list</Card.Title>
                                            <div className="custom-top-nav-tab d-flex mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" onClick={handleClick} data-text="Quote list" id="pills-quote-list-tab" data-bs-toggle="pill" data-bs-target="#pills-quote-list" type="button" role="tab" aria-controls="pills-quote-list" aria-selected="true">
                                                            <Icon path={mdiFileDocumentOutline} className="btn-icon me-1" />Quote list
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" onClick={handleClick} data-text="Draft Quote List" id="pills-draft-quote-list-tab" data-bs-toggle="pill" data-bs-target="#pills-draft-quote-list" type="button" role="tab" aria-controls="pills-draft-quote-list" aria-selected="false">
                                                            <Icon path={mdiListBoxOutline} className="btn-icon me-1" />Draft Quote List
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" onClick={handleClick} data-text="Create Quote" id="pills-create-quote-tab" data-bs-toggle="pill" data-bs-target="#pills-create-quote" type="button" role="tab" aria-controls="pills-create-quote" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Create Quote
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-quote-list" role="tabpanel" aria-labelledby="pills-quote-list-tab" tabIndex="0">
                                        <QuoteList />
                                    </div>
                                    <div className="tab-pane fade" id="pills-draft-quote-list" role="tabpanel" aria-labelledby="pills-draft-quote-list-tab" tabIndex="0">
                                        <QuoteDraftLits />
                                    </div>
                                    <div className="tab-pane fade" id="pills-create-quote" role="tabpanel" aria-labelledby="pills-create-quote-tab" tabIndex="0">
                                        <CreateQuote />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
