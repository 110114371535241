import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const IncomeContext = createContext();

// Custom hook to use the CustomerContext
export const useIncome = () => useContext(IncomeContext);

// Create a Context Provider component
export const IncomeProvider = ({ children }) => {
    const [incomCategoryeData,setIncomeCategoryData] = useState([])

    const fetchIncomeData = async () => {
        try {
            const response = await getFromAPI('FtsExpenseCategory/?isexpense=0');
            setIncomeCategoryData(response);
        } catch (error) {
            console.error('Error fetching Unit data:', error);
        }
    };

    return (
        <IncomeContext.Provider value={{ incomCategoryeData, fetchIncomeData }}>
            {children}
        </IncomeContext.Provider>
    );
};
