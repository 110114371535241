import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import TempData from './temp_data.json';
import FormInputs from './FormInputs';

export default function QuickProductModal({ addProductShow, addProductClose }) {
    const [TempDataOption] = useState(TempData);

    // input value
    const [data, setData] = useState({
        rolesName: "Accountant"
    });

    // Edit modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setData({ ...data, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                { size: { lg: 6 }, label: 'Product Name', type: 'text', name: 'productName', id: 'productName', placeholder: 'Enter product name...', value: data.productName, errormsg: '', },
                {
                    size: { lg: 6 }, label: 'Product Type', type: 'select', name: 'productType', id: 'productType', value: data.productType, errormsg: '',
                    options: [
                        { value: '', label: 'Select Product Type' },
                        { value: '', label: 'Both (Consumable and Retail)' },
                        { value: '', label: 'Consumable' },
                        { value: '', label: 'Retail' },
                    ],
                },
                {
                    size: { lg: 6 }, label: 'Product Category', type: 'selectwithbtn', name: 'productCategory', id: 'productCategory', value: data.productCategory, errormsg: '',
                    options: [
                        { value: '', label: 'Select Product Category' },
                        { value: '', label: 'Product 1' },
                        { value: '', label: 'Product 2' },
                    ],
                },
                {
                    size: { lg: 6 }, label: 'Unit', type: 'selectwithbtn', name: 'productUnit', id: 'productUnit', value: data.productUnit, errormsg: '',
                    options: [
                        { value: '', label: 'Select Unit' },
                        { value: '', label: 'Unit 1' },
                        { value: '', label: 'Unit 2' },
                    ],
                },
                { size: { md: 6, lg: 4 }, label: 'Selling Price', type: 'number', name: 'sellingPrice', id: 'sellingPrice', placeholder: 'Enter price...', value: data.sellingPrice, errormsg: '', },
                { size: { md: 6, lg: 4 }, label: 'MRP', type: 'number', name: 'productMrp', id: 'productMrp', placeholder: 'Enter MRP...', value: data.productMrp, errormsg: '', },
                { size: { lg: 4 }, label: 'Cost', type: 'number', name: 'procuctCost', id: 'procuctCost', placeholder: 'Enter cost...', value: data.procuctCost, errormsg: '', },
                {
                    size: { lg: 4 }, label: 'Vendor', type: 'selectwithbtn', name: 'productVendor', id: 'productVendor', value: data.productVendor, errormsg: '',
                    options: [
                        { value: '', label: 'Select Vendor' },
                        { value: '', label: 'Vendor 1' },
                        { value: '', label: 'Vendor 2' },
                    ],
                },
                {
                    size: { md: 6, lg: 4 }, label: 'Tax', type: 'select', name: 'productTax', id: 'productTax', value: data.productTax, errormsg: '',
                    options: [
                        { value: '', label: 'Select Tax' },
                        { value: '', label: '10%' },
                        { value: '', label: '20%' },
                    ],
                },
                {
                    size: { md: 6, lg: 4 }, label: 'Tax type', type: 'select', name: 'taxType', id: 'taxType', value: data.taxType, errormsg: '',
                    options: [
                        { value: '', label: 'Select tax type' },
                        { value: '', label: 'Exclusive' },
                        { value: '', label: 'Inclusive' },
                    ],
                },
                {
                    size: { md: 12 }, label: 'Branch', type: 'multiSelect', name: 'branch', id: 'branch', value: data.branch, errormsg: '',
                    options: [
                        { value: "All Branch", label: "All Branch" },
                        { value: "NSF Adajan", label: "NSF Adajan" },
                        { value: "NFS Parle Point", label: "NFS Parle Point" },
                        { value: "NFS Uniform", label: "NFS Uniform" },
                        { value: "NFS Warehouse", label: "NFS Warehouse" },
                    ],
                },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Add", onClick: handleSubmit },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <Modal centered show={addProductShow} onHide={addProductClose} size='xl'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Add New Product</h5>
                    <FormInputs Buttons={Buttons} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                </Modal.Body>
            </Modal>
        </>
    )
}
