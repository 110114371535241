import React, { useEffect, useState } from 'react';
// import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Dropdown, Table, Image, Badge, Button } from 'react-bootstrap';
import Chart from "react-apexcharts";

// icons
import Icon from '@mdi/react';
import { mdiReceiptTextOutline, mdiDotsHorizontal, mdiRefresh, mdiFormatListCheckbox, mdiTrashCanOutline, mdiCircle, mdiCircleBoxOutline, mdiReload, mdiDotsVertical, mdiFinance, mdiCardAccountDetailsOutline } from '@mdi/js';

// table-data json files
import purchaseProduct from "./purchase-product-data.json";
import salesProduct from "./sales-product-data.json";
import alertQuantity from "./alert-quantity-data.json";

// img
import user_1 from "../../images/user-img/user_2.jpg";
import user_2 from "../../images/user-img/user_3.jpg";
import user_3 from "../../images/user-img/user_4.jpg";
import user_4 from "../../images/user-img/user_5.jpg";
import user_5 from "../../images/user-img/user_6.jpg";
import user_6 from "../../images/user-img/user_7.jpg";

import charts from "./dashboardChart";
import { getFromAPI} from '../../Utils/utils';
import { fetchTaxData, fetchProductCategoryData, fetchUnitData, fetchBrandData, fetchVendorData } from '../../Utils/function_list';


export default function Dashboard() {
    // table-data
    const [purchaseProductData] = useState(purchaseProduct);
    const [salesProductData] = useState(salesProduct);
    const [alertQuantityData] = useState(alertQuantity);
    const [productData,setProductData] = useState([]);
    const [productCategory, setProductCategory] = useState([]);

    useEffect(() => { 
        fetchProductCategoryData(setProductCategory);
    }, []);

    const fetchProductData = async () => {
        try {
            const response = await getFromAPI('FtsWarehouse/');
            
            // Filter the response to only include products with alert quantities <= 10
            const filteredData = response.filter(
                item => item.alert_consumable_quantity <= 10 || item.alert_retail_quantity <= 10
            );
            setProductData(filteredData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {
        fetchProductData();
    }, []);

    const getProductCategoryNameById = (prodCatID) => {
        let prodCat = productCategory.find(pc => pc.id === prodCatID);
        return prodCat ? prodCat.name : 'Unknown category';
    };


    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    {/* <PageTitle breadcrumbLast="Dashboard" /> */}

                    <Container>
                        <Row>
                            <Col sm={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div>
                                                <h2 className='mb-1'>24</h2>
                                                <p className='mb-0 fs-16 text-muted'>Invoice of the day</p>
                                            </div>
                                            <div className='avatar avatar-xl bg-soft-primary text-primary'>
                                                <Icon path={mdiReceiptTextOutline} className='avatar-icon' />
                                            </div>
                                        </div>
                                        <Chart options={charts.invoiceChart.options} type="bar" series={charts.invoiceChart.series} width="100%" height="120px" />
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div>
                                                <h2 className='mb-1'>$260.00</h2>
                                                <p className='mb-0 fs-16 text-muted'>Sales of the day</p>
                                            </div>
                                            <div className='avatar avatar-xl bg-soft-success text-success'>
                                                <Icon path={mdiFinance} className='avatar-icon' />
                                            </div>
                                        </div>

                                        <Chart options={charts.salesChart.options} type="area" series={charts.salesChart.series} width="100%" height="120px" />
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div>
                                                <h2 className='mb-1'>36</h2>
                                                <p className='mb-0 fs-16 text-muted'>Visits of the week</p>
                                            </div>
                                            <div className='avatar avatar-xl bg-soft-info text-info'>
                                                <Icon path={mdiCardAccountDetailsOutline} className='avatar-icon' />
                                            </div>
                                        </div>

                                        <Chart options={charts.visitsChart.options} type="bar" series={charts.visitsChart.series} width="100%" height="120px" />
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div>
                                                <h2 className='mb-1'>$106.00</h2>
                                                <p className='mb-0 fs-16 text-muted'>Expenses of the day</p>
                                            </div>
                                            <div className='avatar avatar-xl bg-soft-danger text-danger'>
                                                <Icon path={mdiFinance} className='avatar-icon' />
                                            </div>
                                        </div>

                                        <Chart options={charts.expensesChart.options} type="area" series={charts.expensesChart.series} width="100%" height="120px" />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Sales Report</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <Chart options={charts.salesReportChart.options} type="line" series={charts.salesReportChart.series} width="100%" height="295px" />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Provider Status</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href=""><Icon path={mdiRefresh} className='icon-size-14 me-1' /> Refresh</Dropdown.Item>
                                                    <Dropdown.Item href=""><Icon path={mdiFormatListCheckbox} className='icon-size-14 me-1' /> View More</Dropdown.Item>
                                                    <Dropdown.Item href=""><Icon path={mdiTrashCanOutline} className='icon-size-14 me-1' /> Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <Table hover responsive className='table-nowrap mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>Provider</th>
                                                    <th>Attendance</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_1} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Sheila Davis
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-success' /></td>
                                                    <td>
                                                        <Badge bg="soft-success badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_2} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Charlotte Terhune
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-danger' /></td>
                                                    <td>
                                                        <Badge bg="soft-danger badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> In Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_3} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Yolanda Tetrault
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-success' /></td>
                                                    <td>
                                                        <Badge bg="soft-success badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_4} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Faye McDonald
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-success' /></td>
                                                    <td>
                                                        <Badge bg="soft-success badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_5} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Lauri Herrera
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-danger' /></td>
                                                    <td>
                                                        <Badge bg="soft-danger badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> In Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <Image src={user_6} alt="" roundedCircle className='avatar-28'></Image>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                Mary Cambell
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><Icon path={mdiCircle} className='icon-size-16 text-success' /></td>
                                                    <td>
                                                        <Badge bg="soft-success badge-icon"><Icon path={mdiCircleBoxOutline} className='icon-size-16 me-1' /> Active</Badge>{' '}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Top Product Sold</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Polo T-shirt</h5>
                                                <p className='mb-0 fs-14'>Polo Brand T-shirt</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$960.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Headphones</h5>
                                                <p className='mb-0 fs-14'>Boat Brand Headphones</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$810.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Nike Shoes</h5>
                                                <p className='mb-0 fs-14'>Nike Brand Sports Shoes</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$640.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Milton Bottle</h5>
                                                <p className='mb-0 fs-14'>Milton Brand Water Bottle</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$640.00</h4>
                                        </div>
                                        <hr />

                                        <div className='text-center'>
                                            <Button variant="primary">Loading <Icon spin path={mdiReload} className="btn-icon ms-1" /></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} xl={3}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Top Product Sold</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Polo T-shirt</h5>
                                                <p className='mb-0 fs-14'>Polo Brand T-shirt</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$960.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Headphones</h5>
                                                <p className='mb-0 fs-14'>Boat Brand Headphones</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$810.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Nike Shoes</h5>
                                                <p className='mb-0 fs-14'>Nike Brand Sports Shoes</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$640.00</h4>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5 className='fw-normal mb-1 fs-17'>Milton Bottle</h5>
                                                <p className='mb-0 fs-14'>Milton Brand Water Bottle</p>
                                            </div>
                                            <h4 className='mb-0 fs-20'>$640.00</h4>
                                        </div>
                                        <hr />

                                        <div className='text-center'>
                                            <Button variant="primary">Loading <Icon spin path={mdiReload} className="btn-icon ms-1" /></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Purchase Report</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>
                                        <Chart options={charts.purchaseReportChart.options} type="line" series={charts.purchaseReportChart.series} width="100%" height="295px" />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Total Purchase Product</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <Table responsive className='table-nowrap mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Qty</th>
                                                    <th>HSN</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchaseProductData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.productName}</td>
                                                        <td>{data.productQty}</td>
                                                        <td>{data.productHsn}</td>
                                                        <td>{data.productAmount}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Total Sales Product</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <Table responsive className='table-nowrap mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Qty</th>
                                                    <th>HSN</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salesProductData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.productName}</td>
                                                        <td>{data.productQty}</td>
                                                        <td>{data.productHsn}</td>
                                                        <td>{data.productAmount}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex justify-content-between'>
                                            <span>Product Alert Quantity</span>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link drop-link" id="dropdown-basic">
                                                    <Icon path={mdiDotsHorizontal} className='icon-size-24' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end">
                                                    <Dropdown.Item href="">Last 28 Days</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Month</Dropdown.Item>
                                                    <Dropdown.Item href="">Last Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>

                                        <Table responsive className='table-sm table-nowrap mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Categories</th>
                                                    <th>Price</th>
                                                    <th>Retail Qty</th>
                                                    <th>Alert Retail Qty</th>
                                                    <th>Consumable Qty</th>
                                                    <th>Alert Consumable Qty</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.product.description}</td>
                                                        <td>{getProductCategoryNameById(data.product.product_category_id)}</td>
                                                        <td>{data.product.maximum_retail_price}</td>
                                                        <td>{data.instore_retail_qty}</td>
                                                        <td>{data.alert_retail_quantity}</td>
                                                        <td>{data.instore_consumable_qty}</td>
                                                        <td>{data.alert_consumable_quantity}</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light btn-sm px-2" id="dropdown-basic">
                                                                    <Icon path={mdiDotsVertical} className='icon-size-15' />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
