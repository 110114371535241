import React, { useState } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';

import GeneralLedgerTableData from "./general-ledger-table-data.json";

export default function GeneralLedger() {
    const [generalLedgerData] = useState(GeneralLedgerTableData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Card.Title className='mb-md-0'>General Ledger</Card.Title>
                                            <Form className='d-sm-flex'>
                                                <div className='min-sm-w me-sm-3 mb-3 mb-sm-0'>
                                                    <Form.Select  size="sm">
                                                        <option>Last Month</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                    </Form.Select>
                                                </div>
                                                <div className="min-sm-w">
                                                    <Form.Control type="date" placeholder="24 November, 2022" name='saleDate' id='saleDate' size="sm" />
                                                </div>
                                            </Form>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Cash</Card.Title>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Cashdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Cashcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Bank</Card.Title>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Bankdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Bankcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Tax</Card.Title>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Taxdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>2022-11-30</th>
                                                            <th>Closing balance</th>
                                                            <th>104800.01</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Taxcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Product</Card.Title>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Productdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>2022-11-30</th>
                                                            <th>Closing balance</th>
                                                            <th>822054.40</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Productcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Inventory</Card.Title>
                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Inventorydebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Inventorycreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>30-11-2022</th>
                                                            <th>Closing balance</th>
                                                            <th>404855575.84</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Discount</Card.Title>

                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Discountdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Discountcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>30-11-2022</th>
                                                            <th>Closing balance</th>
                                                            <th>17112.14</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Accounts Payable (A/P)</Card.Title>

                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Apdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>30-11-2022</th>
                                                            <th>Closing balance</th>
                                                            <th>356.00</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Apcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Cost Of Goods Sold</Card.Title>

                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Cogsdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Cogscreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>30-11-2022</th>
                                                            <th>Closing balance</th>
                                                            <th>681140.82</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Round Off</Card.Title>

                                        <Row>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Debits(Dr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Roundoffdebitdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.debits}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th>30-11-2022</th>
                                                            <th>Closing balance</th>
                                                            <th>0.56</th>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Col>
                                            <Col xl={6}>
                                                <Table responsive className='border table-nowrap mb-xl-0'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Perticular</th>
                                                            <th>Credit(Cr.)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generalLedgerData.Roundoffcreditdata.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.date}</td>
                                                                <td>{data.perticular}</td>
                                                                <td>{data.credit}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
