import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';

// Create Context
export const GSTContext = createContext();

// Custom hook to use the GSTContext
export const useGST = () => useContext(GSTContext);

// Create a Context Provider component
export const GSTProvider = ({ children }) => {
    const [gstData, setGSTData] = useState([]);

    const fetchGSTData = async () => {
        try {
            const response = await getFromAPI('FtsTaxes/');
            setGSTData(response);
        } catch (error) {
            console.error('Error fetching GST data:', error);
        }
    };

    return (
        <GSTContext.Provider value={{ gstData, fetchGSTData }}>
            {children}
        </GSTContext.Provider>
    );
};
