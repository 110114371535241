import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';


// Create Context
export const BranchAdminContext = createContext();

// Custom hook to use the CustomerContext
export const useBranchAdmin = () => useContext(BranchAdminContext);

// Create a Context Provider component
export const BranchAdminProvider = ({ children }) => {
    const [getBranchAdmin, setBranchAdminData] = useState([]);

    const fetchBranchAdminData = async () => {
        try {
            const response = await getFromAPI("FtsStaff/");
            if(response){
            setBranchAdminData(response);
            }
            else
            {
                console.log("No data found")
            }
        } catch (error) {
            console.error('Error fetching Branch Admin data:', error);
        }
    };

    return (
        <BranchAdminContext.Provider value={{ getBranchAdmin, fetchBranchAdminData }}>
            {children}
        </BranchAdminContext.Provider>
    );
};
