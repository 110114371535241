import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Form } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table-data
import TransferFundsTableData from "./transfer-funds-table-data.json";

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import FormModal from '../../Utils/FormModal';

export default function TransferFunds() {
    // datatable
    const [transferFundsData] = useState(TransferFundsTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableBankFunds')) {
            $('#mainDatatableBankFunds').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    // Edit Received modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleModalOpen = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const [formData, setFormData] = useState({
        referenceNo: '',
        expenseDate: '',
        account: '',
        fromAccount: '',
        toAccount: '',
        description: ''
    });

    const handleInputChange = (index, field, value) => {
        setFormData({ ...formData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                { size: { xl: 4 }, label: 'Reference No', type: 'text', name: 'referenceNo', id: 'referenceNo', placeholder: 'Enter no...', value: formData.referenceNo, errormsg: '', },
                { size: { lg: 6, xl: 4 }, label: 'Expense Date', type: 'date', name: 'expenseDate', id: 'expenseDate', value: formData.expenseDate, errormsg: '', },
                {
                    size: { lg: 6, xl: 4 }, label: 'Account', type: 'select', name: 'account', id: 'account', value: formData.account, errormsg: '', 
                    options: [
                        { value: '', label: 'Cash - 3243948' },
                        { value: '1', label: 'One' },
                        { value: '2', label: 'Two' }
                    ]
                },
                { size: { lg: 6 }, label: 'From Account (Credit)', type: 'text', name: 'fromAccount', id: 'fromAccount', placeholder: 'Search from account...', value: formData.fromAccount, errormsg: '', },
                { size: { lg: 6 }, label: 'To Account (Debit)', type: 'text', name: 'toAccount', id: 'toAccount', placeholder: 'Search to account..', value: formData.toAccount, errormsg: '', },
                { size: { sm: 12 }, label: 'Description', type: 'text', name: 'description', id: 'description', placeholder: 'Enter description...', value: formData.description, errormsg: '', },
            ]
        },
    ];
    const Buttons = [
        { variant: "primary", text: "Generate and New", onClick: handleSubmit },
        { variant: "success", text: "Generate", onClick: handleFormModalClose },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Transfer Funds/JV</Card.Title>
                <div>
                    <div className='d-sm-flex align-items-center'>
                        <Button variant="light me-sm-3 mb-sm-0 mb-3" onClick={handleModalOpen}>
                            <Icon path={mdiPlus} className="btn-icon me-1" /> Add Transfer
                        </Button>
                        <Form className='me-sm-3 mb-sm-0 mb-3'>
                            <Form.Select size="sm">
                                <option>Year to date(YTD)</option>
                                <option value="1">All Time</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                        <Form>
                            <Form.Select size="sm">
                                <option>All</option>
                                <option value="1">Select One</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                    </div>
                </div>
            </div>

            <Table responsive bordered className='table-nowrap' id='mainDatatableBankFunds'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr.No</th>
                        <th>Reference No</th>
                        <th>Date</th>
                        <th>From Account (Credit)</th>
                        <th>To Account (Debit)</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {transferFundsData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.srNo}</td>
                            <td>{data.referenceNo}</td>
                            <td>{data.date}</td>
                            <td>{data.fromAccount}</td>
                            <td>{data.toAccount}</td>
                            <td>{data.amount}</td>
                            <td>{data.description}</td>
                            <td className='py-1 text-end'>
                                <Button variant="soft-info btn-sm px-3"><Icon path={mdiSquareEditOutline} className="btn-icon" /></Button>{' '}
                                <Button variant="soft-danger btn-sm px-3"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* From Modal */}
            <FormModal title="Add Transfer" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
