import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewTax from './ViewTax';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI, postToAPI, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
import GSTConfigurationData from "./gst-configuration-data.json";
import { toast } from "react-toastify";
import { GSTProvider, useGST } from './getGST';
import { gstContext1 } from './gstContext';
// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';
import { fetchTaxData } from '../../../Utils/function_list';

function GSTConfiguration() {

    const initialGstInputData = {
        name: '',
        taxrate: '',
        country_id: 0,
        applicable_date: formatISODateWithTimezone(new Date()),
        iscombined: 1, // Default to 1 (Simple Tax)
        child_ids: 'NA',
        isdefault: 0,
        type: 1,
        description: 'NA',
        created_branch_id: 0,
        isdeleted: 0,
        created_user_id: 0,
        last_modified_user_id: 0,
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        created_date_time: formatISODateWithTimezone(new Date()),
        reserve_varchar1: 'NA',
        reservs_varchar2: 'NA',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: '2024-09-18',

    }

    const [gstInputData, setGstInputData] = useState(initialGstInputData);
    const [countryData, setCountryData] = useState();
    const [getGST, setGST] = useState([]);
    const handleCancel = () => {
        setGstInputData(initialGstInputData);
        setTaxType('simple');
        setEnterTaxRate1('');
        setEnterTaxRate2('');
    };
    // State to store the input values for taxRate1 and taxRate2
    const [enterTaxRate1, setEnterTaxRate1] = useState('');
    const [enterTaxRate2, setEnterTaxRate2] = useState('');

    const [selectedGSTId, setSelectedGSTId] = useState('');

    const { gstData, fetchGSTData } = useGST();

    // New state variables
    const [taxType, setTaxType] = useState('simple'); // 'simple' or 'combine'
    const [isTaxRateDisabled, setTaxRateDisabled] = useState(false);
    const [Tax, setTax] = useState([]);

    useEffect(() => {
        fetchGSTData(setGST);
    }, [selectedGSTId]);

    const handleAddGst = async () => {
        if (!gstInputData.name.trim()) {
            toast.error('Please enter valid Name', 'error');
            return;
        }

        if (!gstInputData.taxrate.trim()) {
            toast.error('Please enter valid Tax Rate', 'error');
            return;
        }

        // Combine the taxRate1 and taxRate2 values, separated by a comma
        const combinedChildIds = `${enterTaxRate1},${enterTaxRate2}`;

        // Split the IDs and map them to names
        const names = combinedChildIds.split(',').map(id => {
            const taxRate = Tax.find(rate => rate.id === parseInt(id.trim()));
            return taxRate ? taxRate.name : 'Unknown';
        });

        // Join the names with ' + ' instead of a comma
        const childDescriptionString = names.join(' + ');

        // Update the gstInputData with the combined child_ids value
        const updatedGstInputData = {
            ...gstInputData,
            description: childDescriptionString,
            child_ids: combinedChildIds, // Set the combined tax rates to child_ids
        };

        try {
            // Post the data to the API
            const response = await postToAPI("FtsTaxes/", updatedGstInputData);
            if (response.status) {
                toast.success('GST Data Added Successfully', 'success');
                setGstInputData(initialGstInputData);
                setEnterTaxRate1('');
                setEnterTaxRate2('');
            }
            else {
                toast.error('Failed to add GST Data');
            }
        } catch (error) {
            toast.error('An unexpected error occurred');
        }
    };

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const selectedGSTId = this.getAttribute('data-id');
                setSelectedGSTId(selectedGSTId);

                // Trigger click on the "View Unit" tab
                const viewGSTTab = document.getElementById("pills-view-tax-tab");
                if (viewGSTTab) {
                    viewGSTTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [gstData]);

    useEffect(() => {
        if (gstData.length === 0) {
            // No vendors available, reset selectedCustomerId
            setSelectedGSTId(null);
        }
    }, [gstData, selectedGSTId]);  // Trigger on changes to 'vendor' and 'selectedCustomerId'

    // Handle Tax Type Change (Simple or Combine)
    const handleTaxTypeChange = (e) => {
        const selectedTaxType = e.target.value;
        setTaxType(selectedTaxType);

        if (selectedTaxType === 'simple') {
            setGstInputData({
                ...gstInputData,
                iscombined: 1,
                child_ids: '',
            });
        } else if (selectedTaxType === 'combine') {
            setGstInputData({
                ...gstInputData,
                iscombined: 2,
                taxrate: '', // Clear tax rate for combine tax
            });
        }
    };

    // Handle Tax Rate 1 Change
    const handleEnterTaxRate1Change = (e) => {
        const value = e.target.value;
        setEnterTaxRate1(value);

        // Update child_ids if tax type is 'combine'
        if (taxType === 'combine') {
            const childIds = [value, enterTaxRate2].filter(Boolean).join(',');
            setGstInputData({
                ...gstInputData,
                child_ids: childIds,
            });
        }
    };

    // Handle Tax Rate 2 Change
    const handleEnterTaxRate2Change = (e) => {
        const value = e.target.value;
        setEnterTaxRate2(value);

        // Update child_ids if tax type is 'combine'
        if (taxType === 'combine') {
            const childIds = [enterTaxRate1, value].filter(Boolean).join(',');
            setGstInputData({
                ...gstInputData,
                child_ids: childIds,
            });
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setGstInputData({
            ...gstInputData,
            [name]: checked ? 1 : 0,
        });
    };

    // Handle input changes for GST data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGstInputData({
            ...gstInputData,
            [name]: value,
        });
    };

    useEffect(() => {
        fetchTaxData(setTax);
    }, []);

    return (
        <>
            <gstContext1.Provider value={selectedGSTId}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-md-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className='' type="text" placeholder="Search GST..." name='gstSearch' id='gstSearch' />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-view-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-view-tax" type="button" role="tab" aria-controls="pills-view-tax" aria-selected="true">
                                                                <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Tax
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-new-tax" type="button" role="tab" aria-controls="pills-new-tax" aria-selected="false">
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Tax
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between align-items-center'>
                                                    <Form className='custom-form-width'>
                                                        <div className='custom-search-bar'>
                                                            <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                            <Form.Control className='' type="text" placeholder="Search GST..." name='gstSearch' id='gstSearch' />
                                                        </div>
                                                    </Form>
                                                    <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                        <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" id="pills-view-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-view-tax" type="button" role="tab" aria-controls="pills-view-tax" aria-selected="true">
                                                                    <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Tax
                                                                </button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-new-tax-tab" data-bs-toggle="pill" data-bs-target="#pills-new-tax" type="button" role="tab" aria-controls="pills-new-tax" aria-selected="false">
                                                                    <Icon path={mdiPlus} className="btn-icon me-1" />New Tax
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {gstData.map((data, i) =>
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                            <Nav.Link className={i === 0 ? "active" : ""}>
                                                                <h5 className='product-tab-title'>{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>

                                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-view-tax" role="tabpanel" aria-labelledby="pills-view-tax-tab" tabIndex="0">
                                            <ViewTax />
                                        </div>
                                        <div className="tab-pane fade" id="pills-new-tax" role="tabpanel" aria-labelledby="pills-new-tax-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <Card.Title>New Tax</Card.Title>
                                                    <Form>
                                                        <Row className='align-items-center'>
                                                            <Col xl={8}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Tax Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder='Enter tax name...'
                                                                        name='name'
                                                                        id='name'
                                                                        value={gstInputData.name}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xl={4}>
                                                                <ul className='list-inline text-xl-center my-3'>
                                                                    <li className='list-inline-item me-4'>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            name="Tax"
                                                                            id="simple-tax-check"
                                                                            label="Simple Tax"
                                                                            value="simple"
                                                                            checked={taxType === 'simple'}
                                                                            onChange={handleTaxTypeChange}
                                                                        />
                                                                    </li>
                                                                    <li className='list-inline-item'>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            name="Tax"
                                                                            id="combine-tax-check"
                                                                            label="Combine Tax"
                                                                            value="combine"
                                                                            checked={taxType === 'combine'}
                                                                            onChange={handleTaxTypeChange}
                                                                        />
                                                                    </li>
                                                                </ul>
                                                            </Col>
                                                            {/* Tax Rate */}
                                                            <Col xl={4}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Tax Rate</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter tax rate..."
                                                                        name="taxrate"
                                                                        value={gstInputData.taxrate}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            {/* Tax Rate 1 and Tax Rate 2 */}
                                                            <Col md={6} xl={4}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Tax Rate 1</Form.Label>
                                                                    <Form.Select
                                                                        name="enterTaxRate1"
                                                                        id="enterTaxRate1"
                                                                        onChange={(e) => setEnterTaxRate1(e.target.value)} // Bind to state
                                                                        disabled={gstInputData.iscombined === 1}  // Disabled for Combine Tax
                                                                        value={enterTaxRate1}
                                                                    >
                                                                        <option value="">Select Tax Rate 1</option>
                                                                        {Tax.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col md={6} xl={4}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Tax Rate 2</Form.Label>
                                                                    <Form.Select
                                                                        name="taxRate2"
                                                                        id="enterTaxRate2"
                                                                        disabled={gstInputData.iscombined === 1}  // Disabled for Combine Tax
                                                                        onChange={(e) => setEnterTaxRate2(e.target.value)} // Bind to state
                                                                        value={enterTaxRate2}                                                                    >
                                                                        <option value="">Select Tax Rate 2</option>
                                                                        {Tax.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <ul className='list-inline mb-3'>
                                                                    <li className='list-inline-item me-4'>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            name="type"
                                                                            id="inclusive-check"
                                                                            label="Inclusive"
                                                                            value="1"
                                                                            checked={gstInputData.type === '1'}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </li>
                                                                    <li className='list-inline-item'>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            name="type"
                                                                            id="exclusive-check"
                                                                            label="Exclusive"
                                                                            value="2"
                                                                            checked={gstInputData.type === '2'}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </li>
                                                                </ul>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="isdefault"
                                                                    name="isdefault"
                                                                    label={`Set this tax as default tax. It will be used by default for business.`}
                                                                    checked={gstInputData.isdefault === 1}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Card.Body>
                                                <div className='custom-card-footer'>
                                                    <div className='text-end btn-list'>
                                                        <Button variant="primary" onClick={handleAddGst}>Add</Button>{' '}
                                                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </gstContext1.Provider>
        </>
    );
}

export default function CustomersWithProvider() {
    return (
        <GSTProvider>
            <GSTConfiguration />
        </GSTProvider>
    );
}