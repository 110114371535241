import React, { useState } from 'react';
import Footer from '../../layout/Footer';
import MembershipDetails from './MembershipDetails';
import AddMembership from './AddMembership';
import { Col, Container, Row, Card, Form, Table, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import MembershipTableData from "./membership-details-data.json";

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountGroup, mdiPlus } from '@mdi/js';

export default function ServicesMemberships() {
  // Membership Table Data
  const [membershipData] = useState(MembershipTableData);
  return (
    <>
      <div className="page-wrapper">
        <div className='page-content'>
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className='d-lg-flex justify-content-between align-items-center'>
                      <Form className='custom-form-width'>
                        <div className='custom-search-bar'>
                          <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                          <Form.Control className='' type="text" placeholder="Search service..." name='customersSearch' id='customersSearch' />
                        </div>
                      </Form>
                      <div className="custom-top-nav-tab mt-3 mt-lg-0">
                        <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-view-membership-tab" data-bs-toggle="pill" data-bs-target="#pills-view-membership" type="button" role="tab" aria-controls="pills-view-membership" aria-selected="true">
                              <Icon path={mdiAccountGroup} className="btn-icon me-1" />View Membership
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-membership-tab" data-bs-toggle="pill" data-bs-target="#pills-membership" type="button" role="tab" aria-controls="pills-membership" aria-selected="false">
                              <Icon path={mdiPlus} className="btn-icon me-1" />New Membership
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={4} xxl={3}>
                <Card className='procust-left-list leftbar-list'>
                  <SimpleBarReact>
                    <Card.Body>
                      <div className='left-list-bar'>
                        {membershipData.membershipLeftListData.map((data, i) =>
                          <Nav.Item className='left-link-item' key={i}>
                            <Nav.Link className={i === 0 ? "active" : ""}>
                              <h5 className='product-tab-title'>{data.listTitle}</h5>
                              <p className='product-tab-sub-title'>{data.listSubTitle}</p>
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </div>
                    </Card.Body>
                  </SimpleBarReact>
                </Card>
              </Col>
              <Col lg={8} xxl={9}>

                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-view-membership" role="tabpanel" aria-labelledby="pills-view-membership-tab" tabIndex="0">
                    <Card className='right-content-details'>
                      <Card.Body>
                        <Card.Title>View Membership</Card.Title>
                        <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-view-vendor" role="tabpanel" aria-labelledby="pills-view-vendor-tab" tabIndex="0">
                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="true">Details</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-customers-tab" data-bs-toggle="pill" data-bs-target="#pills-customers" type="button" role="tab" aria-controls="pills-customers" aria-selected="false">Customers</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-history-tab" data-bs-toggle="pill" data-bs-target="#pills-history" type="button" role="tab" aria-controls="pills-history" aria-selected="false">History</button>
                              </li>
                            </ul>

                            <div className="tab-content" id="pills-tabContent">
                              <div className="tab-pane fade show active" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab" tabIndex="0">
                                <MembershipDetails />
                              </div>

                              <div className="tab-pane fade" id="pills-customers" role="tabpanel" aria-labelledby="pills-customers-tab" tabIndex="0">
                                <Card.Title>Customers</Card.Title>
                                <Table responsive bordered className='table-nowrap'>
                                  <thead className='table-light'>
                                    <tr>
                                      <th>Customer Name</th>
                                      <th>Purchase Date</th>
                                      <th>Due Date</th>
                                      <th>Invoice No.</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Michael K. Sanchez</td>
                                      <td>28/12/2022</td>
                                      <td>30/12/2022</td>
                                      <td>#20156</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>

                              <div className="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab" tabIndex="0">
                                <Card.Title>History</Card.Title>
                                <Table responsive bordered className='table-nowrap'>
                                  <thead className='table-light'>
                                    <tr>
                                      <th>Customer Name</th>
                                      <th>Transaction Date</th>
                                      <th>Description</th>
                                      <th>Transaction Amount</th>
                                      <th>Invoice No.</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Michael K. Sanchez</td>
                                      <td>28/12/2022</td>
                                      <td>Good Product</td>
                                      <td>16,800</td>
                                      <td>#20156</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="tab-pane fade" id="pills-membership" role="tabpanel" aria-labelledby="pills-membership-tab" tabIndex="0">
                    <AddMembership />
                  </div>
                </div>

              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  )
}
