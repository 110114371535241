import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Form, Modal, Row, Col } from 'react-bootstrap';
import FormModal from '../../Utils/FormModal';

// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// table-data
import ViewReceivedTableData from "./view-received-table-data.json";
import TransactionPopupData from './transaction-popup-data.json';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiSquareEditOutline, mdiTrashCanOutline, mdiMagnify } from '@mdi/js';


export default function Received() {
    // datatable
    const [viewReceivedData] = useState(ViewReceivedTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableBankReceived')) {
            $('#mainDatatableBankReceived').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    // Payee Modal
    const [showPayeeModal, setShowPayeeModal] = useState(false);
    const handlePayeeModalClose = () => setShowPayeeModal(false);

    // Invoice Modal
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const handleInvoiceModalClose = () => setShowInvoiceModal(false);

    // Edit Received modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleModalOpen = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const [formData, setFormData] = useState({
        referenceNo: '',
        expenseDate: '',
        account: '',
        payeeDialog: '',
        amount: '',
        chequeNo: '',
        description: ''
    });

    const handleInputChange = (index, field, value) => {
        setFormData({ ...formData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const handlePayeeDialog = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setShowPayeeModal(true);
        }
    };
    const handleAmountDialog = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setShowPayeeModal(true);
        }
    };

    const rows = [
        {
            columns: [
                { size: { xl: 4 }, label: 'Reference No', type: 'text', name: 'referenceNo', id: 'referenceNo', placeholder: 'Enter no...', value: formData.referenceNo, errormsg: '', },
                { size: { lg: 6, xl: 4 }, label: 'Expense Date', type: 'date', name: 'expenseDate', id: 'expenseDate', value: formData.expenseDate, errormsg: '', },
                {
                    size: { lg: 6, xl: 4 }, label: 'Account', type: 'select', name: 'account', id: 'account', value: formData.account, errormsg: '',
                    options: [
                        { value: '', label: 'Cash - 3243948' },
                        { value: '1', label: 'One' },
                        { value: '2', label: 'Two' }
                    ]
                },
                { size: { sm: 12 }, label: 'Payee Dialog', type: 'text', name: 'payeeDialog', id: 'payeeDialog', placeholder: 'Press enter to get Payee dialog...', value: formData.payeeDialog, errormsg: '', onKeyPress: handlePayeeDialog },
                { size: { lg: 6 }, label: 'Amount', type: 'text', name: 'invoiceAmount', id: 'invoiceAmount', placeholder: 'Press enter to get list of Invoices/Purchase Invoices...', value: formData.invoiceAmount, errormsg: '', onKeyPress: handleAmountDialog },
                { size: { lg: 6 }, label: 'Cheque No', type: 'text', name: 'chequeNo', id: 'chequeNo', placeholder: 'Enter cheque no...', value: formData.chequeNo, errormsg: '', },
                { size: { sm: 12 }, label: 'Description', type: 'text', name: 'description', id: 'description', placeholder: 'Enter description...', value: formData.description, errormsg: '', },
            ]
        },
    ];
    const Buttons = [
        { variant: "primary", text: "Generate and New", onClick: handleSubmit },
        { variant: "success", text: "Generate", onClick: handleFormModalClose },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0'>Received</Card.Title>
                <div>
                    <div className='d-sm-flex align-items-center'>
                        <Button variant="light me-sm-3 mb-sm-0 mb-3" onClick={handleModalOpen}>
                            <Icon path={mdiPlus} className="btn-icon me-1" /> Add Received
                        </Button>
                        <Form className='me-sm-3 mb-sm-0 mb-3'>
                            <Form.Select size="sm">
                                <option>Year to date(YTD)</option>
                                <option value="1">All Time</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                        <Form>
                            <Form.Select size="sm">
                                <option>All</option>
                                <option value="1">Select One</option>
                                <option value="2">Two</option>
                            </Form.Select>
                        </Form>
                    </div>
                </div>
            </div>

            <Table responsive bordered className='table-nowrap' id='mainDatatableBankReceived'>
                <thead className='table-light'>
                    <tr>
                        <th>Sr.No</th>
                        <th>Reff No</th>
                        <th>Account</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Payment Mode</th>
                        <th>Payee Type</th>
                        <th>Payee</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {viewReceivedData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.srNo}</td>
                            <td>{data.reffNo}</td>
                            <td>{data.account}</td>
                            <td>{data.date}</td>
                            <td>{data.category}</td>
                            <td>{data.amount}</td>
                            <td>{data.paymentMode}</td>
                            <td>{data.payeeType}</td>
                            <td>{data.payee}</td>
                            <td className='py-1 text-end'>
                                <Button variant="soft-info btn-sm px-3"><Icon path={mdiSquareEditOutline} className="btn-icon" /></Button>{' '}
                                <Button variant="soft-danger btn-sm px-3"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Payee Dialog Modal */}
            <Modal size='lg' centered show={showPayeeModal} onHide={handlePayeeModalClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Payee Dialog List</h5>
                    <div className="search-input">
                        <Form.Control type="text" placeholder="Search..." />
                        <Icon path={mdiMagnify} size={1} className="search-icon" />
                    </div>
                    <Table responsive bordered hover className='mt-3'>
                        <thead className='table-light'>
                            <tr >
                                {TransactionPopupData.payeeHeadData.map((data, i) => (
                                    <th key={i}>{data.tableHeadTitle}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {TransactionPopupData.payeeData.map((data, i) => (
                                <tr key={i}>
                                    <td>{data.payeeName}</td>
                                    <td>{data.payeeCode}</td>
                                    <td>{data.payeeBalance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className='d-flex align-items-center gap-3 '>
                            <h6 className='mb-0'>GST No: 1234567893214</h6>
                            <span className='text-muted opacity-75'>|</span>
                            <h6 className='mb-0'>Email: Youremail@gmail.com</h6>
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <Form.Label className='mb-0'>Balance:</Form.Label>
                            <Form.Control size='sm' type="text" placeholder="$1,200.00" readOnly className='input-width-200' />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Invoice Amount Modal */}
            <Modal size='xl' centered show={showInvoiceModal} onHide={handleInvoiceModalClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Invoice List</h5>
                    <Row className='align-items-end'>
                        <Col lg={9}>
                            <div className="search-input mb-lg-0 mb-3">
                                <Form.Control type="text" placeholder="Search..." />
                                <Icon path={mdiMagnify} size={1} className="search-icon" />
                            </div>
                        </Col>
                        <Col lg={3} >
                            <Form.Check className='float-end' type="checkbox" id="default-checkbox" label="Show paid invoices" />
                        </Col>
                    </Row>

                    <Table responsive bordered hover className='table-nowrap mt-3 mb-4'>
                        <thead className='table-light'>
                            <tr >
                                {TransactionPopupData.invAmtHeadData.map((data, i) => (
                                    <th key={i}>{data.tableHeadTitle}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {TransactionPopupData.invAmtData.map((data, i) => (
                                <tr key={i}>
                                    <td>{data.invAmtPiNo}</td>
                                    <td>{data.invAmtBillNo}</td>
                                    <td>{data.invAmtType}</td>
                                    <td>{data.invAmtDate}</td>
                                    <td>{data.invAmtAmount}</td>
                                    <td>{data.invAmtPay}</td>
                                    <td>{data.invAmtPending}</td>
                                    <td>{data.invAmtAdjust}</td>
                                    <td>{data.invAmtDiscount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Form>
                        <Row className='align-items-end'>
                            <Col lg={10}>
                                <Row>
                                    <Col sm={4}>
                                        <div className="mt-3 custom-form-input">
                                            <Form.Label>Adjusted</Form.Label>
                                            <Form.Control type="text" name='adjustedAmount' id='adjustedAmount' placeholder="" />
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div className="mt-3 custom-form-input">
                                            <Form.Label>Balance</Form.Label>
                                            <Form.Control type="text" name='balanceAmount' id='balanceAmount' placeholder="" />
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div className="mt-3 custom-form-input">
                                            <Form.Label>Bill Pending</Form.Label>
                                            <Form.Control type="text" name='billPendingedAmount' id='billPendingedAmount' placeholder="" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={2} className='mt-3'>
                                <Button variant="primary w-100 btn-lg" type="submit">Add</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* From Modal */}
            <FormModal title="Received" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
