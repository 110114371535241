import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../Utils/utils';


// Create Context
export const AccountContext = createContext();

// Custom hook to use the CustomerContext
export const useAccount = () => useContext(AccountContext);

// Create a Context Provider component
export const AccountProvider = ({ children }) => {
    const [getAccountData, setAccountData] = useState([]);

    const fetchAccountData = async () => {
        try {
            const response = await getFromAPI('FtsAccount/');
            setAccountData(response);
        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };

    return (
        <AccountContext.Provider value={{ getAccountData, fetchAccountData }}>
            {children}
        </AccountContext.Provider>
    );
};
