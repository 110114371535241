import React, { useEffect, useState } from 'react'; 
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
import { getFromAPI, handleDateFormat } from '../../../Utils/utils';
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

export default function ProductLedgerReport({ tableName = "Product Ledger Report" }) {
    const [productData, setProductData] = useState([]);
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState(0);
    const [suggestions, setSuggestions] = useState([]); // State to hold suggestions

    const fetchData = async () => {
        if (productName) {
            try {
                const response1 = await getFromAPI(`FtsItems/?description__startswith=${productName}`);
                setSuggestions(response1); // Store the suggestions in the state
                const response = await getFromAPI(`FtsItems/?description=${productName}`);
                if (response && response.length > 0) {
                    setProductId(response[0].id); // Set the product ID of the first suggestion
                } else {
                    setProductId(0); // If no product found, reset the product ID
                    setProductData([]); // Clear data when no matching product is found
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
                setSuggestions([]); // Clear suggestions if error occurs
                setProductData([]); // Clear data on error
            }
        } else {
            setSuggestions([]); // Clear suggestions if input is empty
            setProductData([]); // Clear data when no product name is entered
        }
    };

    useEffect(()=>{
        fetchData();
    },[productName])
    
    const fetchTransactionData = async () => {
        if (!productId) {
            return; // Skip fetching data if no productId is selected
        }
        try {
            const response = await getFromAPI(`FtsWarehouseTransaction/?product_id=${productId}`);
            const filteredData = response.filter(item =>
                (item.from_type === "2" || item.to_type === "2") &&
                (item.to_warehouse_id === 2)
            );
            setProductData(filteredData);
        } catch (error) {
            console.error('Error fetching transaction data:', error);
            setProductData([]); // Clear data on error
        }
    };
    
    useEffect(() => {
        if (productId) {
            fetchTransactionData();
        } else {
            setProductData([]); // Clear data if productId is not available
        }
    }, [productId]);
    
    // Handle product name change and update state
    const handleProductNameChange = (e) => {
        setProductName(e.target.value); // Update product name
        if (!e.target.value) {
            setSuggestions([]); // Clear suggestions when input is cleared
            setProductData([]); // Optionally, clear the table data if no input is provided
        }
    };
    
    // Handle selecting a suggestion from the list
    const handleSuggestionSelect = (suggestion) => {
        setProductName(suggestion.description); // Set selected suggestion in input
        setSuggestions([]); // Clear the suggestions after selection
    };

    const TransactionTypeNames = {
        1: "Opening Qty",
        2: "Purchase Invoice",
        3: "Internal Branch Transfer",
        4: "Transfer Order",
        5: "Invoice Retail",
        6: "Invoice Consumable Service",
        7: "Return Stock",
        8: "Check Out",
        9: "Adjustment",
        10: "Challan",
        11: "Purchase Return",
        12: "Sales Return",
        13: "Convert Stock",
    };

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const initializeDataTable = () => {
        const tableId = '#mainDatatableProductLReport';

        if ($.fn.DataTable.isDataTable(tableId)) {
            $(tableId).DataTable().clear().destroy();
        }

        let currentBalance = 0;
        const tableData = productData.map((data, index) => {
            const quantity = data.quantity;
            let debit = 0;
            let credit = 0;

            if (quantity < 0) {
                debit = Math.abs(quantity);
            } else if (quantity > 0) {
                credit = quantity;
            }

            currentBalance += credit - debit;
            const transactionTypeWithDescription = `${TransactionTypeNames[data.transaction_type] || "Unknown"}${data.description ? ` (${data.description})` : ''}`;

            return [
                index + 1,
                handleDateFormat(data.created_date_time),
                transactionTypeWithDescription,
                data.id || "N/A",
                debit,
                credit,
                currentBalance
            ];
        });

        $(tableId).DataTable({
            data: tableData,
            columns: [
                { title: "SR No." },
                { title: "Date" },
                { title: "Particular" },
                { title: "Reference" },
                { title: "Debit" },
                { title: "Credit" },
                { title: "Closing Balance" }
            ],
            dom: "Bflrtip",
            buttons: [
                { extend: 'pdf', className: 'btn btn-outline-secondary', text: 'Export to Pdf' },
                { extend: 'print', className: 'btn btn-outline-secondary', text: 'Export to Print' },
                { extend: 'excel', className: 'btn btn-outline-secondary', text: 'Export to Excel' }
            ],
            language: {
                search: '',
                searchPlaceholder: "Search...",
                paginate: {
                    previous: '«',
                    next: '»'
                },
            }
        });
    };

    useEffect(() => {
        initializeDataTable();
    }, [productData]);

    return (
        <div className="page-wrapper">
            <div className='page-content'>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{tableName}</Card.Title>
                                    <Form>
                                        <div className='d-md-flex justify-content-md-between align-items-center'>
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w me-sm-3 me-0">
                                                <Form.Label>Time Duration</Form.Label>
                                                <Form.Select name='timeDuration' id='timeDuration'>
                                                    <option>Select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </div>
                                            <div className="mb-3 custom-form-input custom-form-input-sm min-w">
                                                <Form.Label>Product Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name='productName'
                                                    id='productName'
                                                    placeholder="Enter product name..."
                                                    value={productName}
                                                    onChange={handleProductNameChange}
                                                    autocomplete="off"
                                                />
                                                {suggestions.length > 0 && (
                                                    <ul className="suggestion-list">
                                                        {suggestions.map((suggestion) => (
                                                            <li
                                                                key={suggestion.id}
                                                                onClick={() => handleSuggestionSelect(suggestion)}
                                                            >
                                                                {suggestion.description}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                    <Card className='border shadow-none mb-0'>
                                        <Card.Body>
                                            <Table responsive bordered className='table-nowrap' id='mainDatatableProductLReport'>
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>Date</th>
                                                        <th>Particular</th>
                                                        <th>Reference</th>
                                                        <th>Debits</th>
                                                        <th>Credits</th>
                                                        <th>Closing Balance</th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}
