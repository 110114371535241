import React, { useState } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import CustomerFeedbackData from "./customer-feedback-data.json";

// Icons
import Icon from '@mdi/react';
import { mdiFormatQuoteOpen, mdiStar, mdiReload } from '@mdi/js';

export default function CustomerFeedback() {
    const [customerFeedback] = useState(CustomerFeedbackData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            {customerFeedback.map((data, i) =>
                                <Col md={6} xl={4} key={i}>

                                    <Card>
                                        <Card.Body>
                                            <div className="text-center">
                                                <Icon path={mdiFormatQuoteOpen} className='icon-size-60 text-primary opacity-25' />
                                                <p className='text-muted my-4 lh-22 fst-italic fs-16 mx-xl-5 px-xxl-5'>{data.clientReview}</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img src={require(`../../../images/user-img/${data.clientImg}`)} alt="" className='avatar-xl rounded-circle img-thumbnail' />
                                                </div>
                                                <div className="flex-grow-1 ms-3 ps-1">
                                                    <h5 className='fs-16'>{data.clientName}</h5>
                                                    <p className='text-muted mb-0'>{data.clientSubText}</p>
                                                </div>
                                                <div>
                                                    <Icon path={mdiStar} className={`icon-size-22 ${data.startOne}`} />
                                                    <Icon path={mdiStar} className={`icon-size-22 ${data.startTwo}`} />
                                                    <Icon path={mdiStar} className={`icon-size-22 ${data.startThree}`} />
                                                    <Icon path={mdiStar} className={`icon-size-22 ${data.startFour}`} />
                                                    <Icon path={mdiStar} className={`icon-size-22 ${data.startFive}`} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                        </Row>

                        <Row>
                            <Col className='text-center my-3'>
                                <Button variant="primary">View More <Icon path={mdiReload} className="btn-icon ms-1" spin /></Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
