import React, { useEffect } from 'react';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SendSms from './SendSms';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiTrashCanOutline, mdiMagnify, mdiPlus } from '@mdi/js';

export default function Marketing() {
    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableSMSHistory')) {
            $('#mainDatatableSMSHistory').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='custom-top-nav-tab'>
                                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-send-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-send-sms" type="button" role="tab" aria-controls="pills-send-sms" aria-selected="true">Send SMS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-automated-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-automated-sms" type="button" role="tab" aria-controls="pills-automated-sms" aria-selected="false">Automated SMS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-schedule-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-schedule-sms" type="button" role="tab" aria-controls="pills-schedule-sms" aria-selected="false">Schedule SMS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-custom-sms-tab" data-bs-toggle="pill" data-bs-target="#pills-custom-sms" type="button" role="tab" aria-controls="pills-custom-sms" aria-selected="false">Custom SMS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-custom-target-segment-tab" data-bs-toggle="pill" data-bs-target="#pills-custom-target-segment" type="button" role="tab" aria-controls="pills-custom-target-segment" aria-selected="false">Custom Target Segment</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-manage-groups-tab" data-bs-toggle="pill" data-bs-target="#pills-manage-groups" type="button" role="tab" aria-controls="pills-manage-groups" aria-selected="false">Manage Groups</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-sms-history-tab" data-bs-toggle="pill" data-bs-target="#pills-sms-history" type="button" role="tab" aria-controls="pills-sms-history" aria-selected="false">SMS History</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-sms-conversion-tab" data-bs-toggle="pill" data-bs-target="#pills-sms-conversion" type="button" role="tab" aria-controls="pills-sms-conversion" aria-selected="false">SMS Conversion</button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-send-sms" role="tabpanel" aria-labelledby="pills-send-sms-tab" tabIndex="0">
                                                    <SendSms />
                                                </div>
                                                <div className="tab-pane fade" id="pills-automated-sms" role="tabpanel" aria-labelledby="pills-automated-sms-tab" tabIndex="0">
                                                    <Card.Title>Automated SMS</Card.Title>
                                                    <Form>
                                                        <Row>
                                                            <Col md={6} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Form.Control type="text" placeholder="Enter name" name='useName' id='useName' />
                                                                </div>
                                                            </Col>
                                                            <Col md={6} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Automated Date</Form.Label>
                                                                    <Form.Select  name='automatedDate' id='automatedDate'>
                                                                        <option>No</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col md={6} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Target Segments</Form.Label>
                                                                    <Form.Select  name='targetSegments' id='targetSegments'>
                                                                        <option>Select Target Segment</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col md={6} xxl={3}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>SMS Templates</Form.Label>
                                                                    <Form.Select  name='smsTemplates' id='smsTemplates'>
                                                                        <option>Compose SMS Template</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                    </Form.Select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} placeholder="Type description..." name='description' id='description' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="btn-list text-end">
                                                            <Button variant="primary">Save</Button>
                                                            <Button variant="secondary">Cancel</Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="tab-pane fade" id="pills-schedule-sms" role="tabpanel" aria-labelledby="pills-schedule-sms-tab" tabIndex="0">
                                                    <Card.Title>Schedule SMS</Card.Title>
                                                    <Card className='border shadow-none'>
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <h5 className='fs-16 mb-3'>Add Schedule SMS</h5>
                                                                    <h5 className='fs-16 mb-3'>Add Customers - Welcome</h5>
                                                                    <h5 className='fs-16 mb-3'>Hi, Welcome to F spa and salon, Thank you for making us your choice.</h5>
                                                                    <h5 className='fs-16 mb-3'>Selected Customers</h5>
                                                                    <h5 className='fs-16 mb-0'>JK Patel (9687623125)</h5>
                                                                </div>
                                                                <a href="/">
                                                                    <div className='avatar avatar-sm bg-soft-danger text-danger'>
                                                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <Card className='border shadow-none mb-0'>
                                                        <Card.Body className='pb-0'>
                                                            <Card.Title>Selected Customers</Card.Title>
                                                            <Row className='justify-content-between align-items-end'>
                                                                <Col lg={9} xl={8} xxl={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-3 custom-form-input">
                                                                                <Form.Label>Selected Customers</Form.Label>
                                                                                <Form.Select >
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3 custom-form-input">
                                                                                <Form.Label>Customer Name</Form.Label>
                                                                                <Form.Select >
                                                                                    <option>Select Customer Name</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={3} xl={4} xxl={3}>
                                                                    <Form className='custom-form-width mw-100 mb-3'>
                                                                        <div className='custom-search-bar'>
                                                                            <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                                            <Form.Control className='' type="text" placeholder="Search customers..." name='customersSearch' id='customersSearch' />
                                                                        </div>
                                                                    </Form>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="tab-pane fade" id="pills-custom-sms" role="tabpanel" aria-labelledby="pills-custom-sms-tab" tabIndex="0">
                                                    <Card.Title>Custom SMS</Card.Title>
                                                    <Form>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Form.Control type="text" placeholder="Enter name..." name='customSmsName' id='customSmsName' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Sms Text Content</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} placeholder="Type sms..." name='smsTextContent' id='smsTextContent' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} placeholder="Type description..." name='customSmsdescription' id='customSmsdescription' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="text-end">
                                                            <Button variant="primary"><Icon path={mdiPlus} className="btn-icon me-1" /> Add Template</Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="tab-pane fade" id="pills-custom-target-segment" role="tabpanel" aria-labelledby="pills-custom-target-segment-tab" tabIndex="0">
                                                    <Card.Title>Custom Target Segment</Card.Title>
                                                    <Card className='border shadow-none mb-0'>
                                                        <Card.Body>
                                                            <h5 className='fs-16 fw-normal mb-3'>SMS Marketing [Custom SMS Templates]</h5>
                                                            <h5 className='fs-16 fw-normal mb-3'>Custom SMS Templates</h5>
                                                            <div className="text-end">
                                                                <Button variant="primary"><Icon path={mdiPlus} className="btn-icon me-1" /> Add Template</Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="tab-pane fade" id="pills-manage-groups" role="tabpanel" aria-labelledby="pills-manage-groups-tab" tabIndex="0">
                                                    <Card.Title>Manage Groups</Card.Title>
                                                    <Card className='border shadow-none mb-0'>
                                                        <Card.Body>
                                                            <h5 className='fs-16 fw-normal mb-3'>SMS Marketing [Manage Groups]</h5>
                                                            <h5 className='fs-16 fw-normal mb-3'>Groups</h5>
                                                            <div className="text-end">
                                                                <Button variant="primary">View Invoice</Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="tab-pane fade" id="pills-sms-history" role="tabpanel" aria-labelledby="pills-sms-history-tab" tabIndex="0">
                                                    <Card.Title>SMS History</Card.Title>
                                                    <Row>
                                                        <Col xxl={4}>
                                                            <div className="mb-3">
                                                                <Form.Select  name='smsHistorySelect1' id='smsHistorySelect1' >
                                                                    <option>Select</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={4}>
                                                            <div className="mb-3">
                                                                <Form.Select  name='smsHistorySelect2' id='smsHistorySelect2' >
                                                                    <option>Select</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={4}>
                                                            <div className="mb-3">
                                                                <Form.Select  name='smsHistorySelectToday' id='smsHistorySelectToday' >
                                                                    <option>Today</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <ul className='list-inline text-dark mb-1 fs-16'>
                                                        <li className='list-inline-item me-5 mb-2 pe-5'>0 Sucessful</li>
                                                        <li className='list-inline-item me-5 mb-2 pe-5'>0 Failed</li>
                                                        <li className='list-inline-item me-5 mb-2 pe-5'>0 Total</li>
                                                        <li className='list-inline-item me-5 mb-2 pe-5'>0 DND</li>
                                                        <li className='list-inline-item'>0 Unknown</li>
                                                    </ul>
                                                    
                                                    <Table responsive bordered className='table-nowrap' id='mainDatatableSMSHistory'>
                                                        <thead className='table-light'>
                                                            <tr>
                                                                <th>Sms Content</th>
                                                                <th>Created Date and Time</th>
                                                                <th>Customer Name</th>
                                                                <th>Customer Mobile No</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>500</td>
                                                                <td>28/12/2022 <span className='ms-3'>09:15 AM</span></td>
                                                                <td>Sarah C. Jolley</td>
                                                                <td>(+91) 1234 5678 90</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="tab-pane fade" id="pills-sms-conversion" role="tabpanel" aria-labelledby="pills-sms-conversion-tab" tabIndex="0">
                                                    <Card.Title>SMS Conversion</Card.Title>
                                                    <h5 className='fs-16 fw-normal mb-3'>SMS Marketing [SMS Conversion]</h5>
                                                    <Card className='border shadow-none mb-0'>
                                                        <Card.Body className='pb-0'>
                                                            <Card.Title>Selected Customers</Card.Title>
                                                            <Row className='justify-content-between align-items-end'>
                                                                <Col lg={9} xl={8} xxl={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <Form.Select >
                                                                                    <option>All Time</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div className="mb-3">
                                                                                <Form.Select >
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={3} xl={4} xxl={3}>
                                                                    <Form className='custom-form-width mw-100 mb-3'>
                                                                        <div className='custom-search-bar'>
                                                                            <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                                            <Form.Control className='' type="text" placeholder="Search..." name='customersSearch' id='customersSearch' />
                                                                        </div>
                                                                    </Form>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
