// constants.js

export const ProductType = {
    BOTH: 0,
    CONSUMABLE: 1,
    RETAIL: 2,
};

export const StoreType = {
    BOTH: 0,
    INSTORE: 1,
    ONFLOOR: 2,
};

export const TransactionType = {
    OPENING_QTY: 1,
    PURCHASE_INVOICE: 2,
    INTERNAL_BRANCH_TRANSFER: 3,
    TRANSFER_ORDER: 4,
    INVOICE_RETAIL: 5,
    INVOICE_CONSUMABLE_SERVICE: 6,
    RETURN_STOCK: 7,
    CHECKOUT: 8,
    ADJUSTMENT: 9,
    CHALLAN: 10,
    PURCHASE_RETURN: 11,
    SALES_RETURN: 12,
    CONVERT_STOCK: 13,
};

export const Module = {
    PURCHASE_ORDER: 1,
    PURCHASE_INVOICE: 2,
    INVOICE: 3,
};
