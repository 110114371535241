import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewProductCategory from './ViewProductCategory';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI,postToAPI,formatISODateWithTimezone } from '../../../Utils/utils';
import ProductCategoryData from "./Product-category-data.json";
import { fetchProductCategoryData} from '../../../Utils/function_list';
import {toast} from 'react-toastify';
// Icon
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';
import { productContext } from './categoryContext';
import { ProductProvider, useProduct } from  './getCategory';

function ProductCategory() {
   
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    const [productCategory, setProductCategory] = useState([]);
    const [selectProductId, setSelectProductId] = useState('');
    
    const initialProductData = {
        name: '',
        description: '',
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        isdeleted: 0,
        created_date_time: formatISODateWithTimezone(new Date()),  
        last_modified_date_time: formatISODateWithTimezone(new Date())
    };

    
    const fetchProductCategoryData1 = async () => {
        try {
            const response = await getFromAPI('FtsProductCategory/');
            setProductCategory(response);  // update the state with the fetched data
        } catch (error) {
            console.error('Error fetching Unit data:', error);
        }
    };

    useEffect(() => {
        fetchProductCategoryData1();
    }, []);
    
    const [productCategoryInputData, setproductCategoryInoutData] = useState(initialProductData);

    const handleCancel = () => {
        setproductCategoryInoutData(initialProductData);
    }

    const handleAddProductCategory = async () => {

        if (!productCategoryInputData.name.trim()) {
            toast.error('Please enter valid Product Category', 'error');
            return;
        }
        if (!productCategoryInputData.description.trim()) {
            toast.error('Please enter valid Product Description', 'error');
            return;
        }
        try {
            // Post the data to the API
            const response = await postToAPI("FtsProductCategory/", productCategoryInputData);

            if (response.status) {
                toast.success('Product Category Added Successfully', 'success');
                setproductCategoryInoutData(initialProductData)
                fetchProductCategoryData1();
            } else {
                toast.error('Product Category Added Already Exists!', 'error');
            }
        } catch (error) {
            toast.error('Product Category Added Already Exists!', 'error');
            console.error('Error adding Unit:', error);
        }
    };


    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting
    
        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");
    
                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');
    
                // Update selected category ID
                const productId = this.getAttribute('data-id');
                setSelectProductId(productId);
    
                // Trigger click on the "View Unit" tab
                const viewProductTab = document.getElementById("pills-view-product-category-tab");
                if (viewProductTab) {
                    viewProductTab.click();  // Ensure the tab is switched programmatically
                }
      
            });
        });
    
        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => {});
            });
        };
    }, [productCategory]);

    useEffect(() => {
        if (productCategory.length > 0&&!selectProductId) {
            // No vendors available, reset selectedCustomerId
            setSelectProductId(productCategory[0].id);
        }
    }, [productCategory, setSelectProductId]);  // Trigger on changes to 'product category' and 'selectedUnitId'
    
    
    return (
        <>
        <productContext.Provider value={selectProductId}>

            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search product category..." name='productCategorySearch' id='productCategorySearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-product-category-tab" data-bs-toggle="pill" data-bs-target="#pills-view-product-category" type="button" role="tab" aria-controls="pills-view-product-category" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Product Category
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-product-category-tab" data-bs-toggle="pill" data-bs-target="#pills-new-product-category" type="button" role="tab" aria-controls="pills-new-product-category" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Product Category
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {productCategory.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.name}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-product-category" role="tabpanel" aria-labelledby="pills-view-product-category-tab" tabIndex="0">
                                        <ViewProductCategory />
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-product-category" role="tabpanel" aria-labelledby="pills-new-product-category-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>New Product Category</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Product Category Name</Form.Label>
                                                                <Form.Control 
                                                                   type="text" 
                                                                   placeholder='Enter Roles Name...' 
                                                                   name='productCategoryName' 
                                                                   id='productCategoryName' 
                                                                   value={productCategoryInputData.name}   
                                                                   onChange={(e) => setproductCategoryInoutData({...productCategoryInputData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Product Description</Form.Label>
                                                                <Form.Control 
                                                                   as="textarea" 
                                                                   placeholder='Type product description...' 
                                                                   rows={4}
                                                                   name='rolesName' 
                                                                   id='rolesName' 
                                                                   value={productCategoryInputData.description}
                                                                   onChange={(e) => setproductCategoryInoutData({...productCategoryInputData, description: e.target.value })}
                                                                   />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className='text-end btn-list'>
                                                    <Button variant="primary" onClick = {handleAddProductCategory}>Add</Button>{' '}
                                                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            </productContext.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <ProductProvider>
            <ProductCategory />
        </ProductProvider>
    );
}